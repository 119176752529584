/******* home css ***********/
.mb_90 {
    margin-bottom: 90px;
}
.refer_banner_slide {
    background-color: var(--pri_color);
    padding: 33px 53px;
}
.banner_right_img {
    height: 500px;
    width: auto !important;
}
.banner_heading {
    color: #D3D2D7;
    font-size: 67.778px;
    line-height: 87px;
    width: 376px;
    text-transform: uppercase;
}
.buy_now {
    background-color: var(--pri_color);
    border-radius: 8px;
    border: none;
    padding: 13.5px 50.5px;
}
.mr_36 {
    margin-right: 36px !important;
}
.view_detail {
    background-color: #383349;
    border-radius: 6px;
    border: none;
    padding: 13.5px 42.5px;
}
button {
    cursor: pointer;
}
.refer_banner_slide .carousel-indicators li {
    width: 9px;
    height: 9px;
    background: rgba(242, 242, 242, 0.4);
    border-radius: 50%;
    margin: 0 6.5px;
}
.refer_banner_slide .carousel-indicators li.active {
    background-color: var(--def_color);
}
.refer_banner_slide .carousel-indicators li::after, .refer_banner_slide .carousel-indicators li::before {
    display: none;
}
.refer_banner_slide .carousel-indicators {
    margin: 0;
    bottom: 33px;
}
.banner_text {
    color: #BDBBC2;
}
.pl_12 {
    padding-left: 12px;
}
.mb_17 {
    margin-bottom: 17px;
}
.farmulated_reound {
    width: 46px;
    height: 46px;
    border: 2px solid #BDBBC2;
    border-radius: 50%;
}
.carousel .farmulated_reound img {
    width: auto;
}
.formulated_ul {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 200px;
}
.farmulated_reound + * {
    width: calc(100% - 46px);
}
.font_41 {
    font-size: 41.8912px;
    line-height: 65px;
}
.gray_bg {
    background-color: var(--sec_color);
}
.best_selling_section {
    padding: 44px 21px;
    display: block;
}
.mb_49 {
    margin-bottom: 49px;
}
.best_sell_pro_div .refer-img-wrapper {
    margin-bottom: 21px;
    margin-left: auto;
    margin-right: auto;
}
.best_sell_pro_div .refer-img-wrapper a {
    width: 140px;
    position: relative;
}
.best_sell_pro_div .refer-img-wrapper a::after {
    content: "";
    width: 100%;
    height: 42px;
    display: block;
    background-color: #B7B5BE;
    filter: blur(15px);
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    z-index: 1;
}
.best_sell_pro_div .refer-img-wrapper a img {
    position: relative;
    z-index: 2;
}
.font_20 {
    font-size: 20px;
    line-height: 31px;
}
.text-uppercase {
    text-transform: uppercase;
}
.mb_2 {
    margin-bottom: 2px;
}
.mb_24 {
    margin-bottom: 24px;
}
.pro_price {
    background-color: var(--pri_color);
    border-radius: 6px;
    padding: 8px 13px;
}
.best_selling_para {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
}
.para_des {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.line_through {
    text-decoration: line-through;
}
.mt_7 {
    margin-top: 7px;
}
.view_more_div a {
    padding: 0 !important;
}
.mb_28 {
    margin-bottom: 28px;
}
.explore_product_section {
    padding: 57px 30px 103px 30px;
    background-color: #A7A4AE;
}
.explore_img {
    border-radius: 50%;
    width: 257px;
    height: 257px;
    background-color: var(--sec_color);
}
.mb_41 {
    margin-bottom: 41px;
}
.exp_col_col {
    width: 63%;
    margin: 0 auto;
}
.mb_20 {
    margin-bottom: 20px;
}
.shop_goal_section {
    padding: 55px 38px 80px 38px;
}
.goal_row.row {
    margin-left: -6.5px;
    margin-right: -6.5px;
    width: calc(100% + 13px);
}
.goal_row.row>* {
    padding-left: 6.5px;
    padding-right: 6.5px;
}
.shop_col {
    padding: 45px 50px;
    background-color: var(--def_color);
}
.shop_more {
    background-color: var(--pri_color);
    border-radius: 8px;
    padding: 6px 15px;
    border: 1px solid var(--pri_color);
}
.shop_col .font_14 {
    line-height: 20px;
}
.shop_img_rect {
    background: linear-gradient(180deg, #A7A4AE 0%, rgba(167, 164, 174, 0) 96.69%);
    border-radius: 26px 0px 0px 0px;
    width: 253px;
    height: 243px;
    margin-left: auto;
    margin-bottom: -45px;
    margin-right: -50px;
}
.shop_img_rect img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}
.mt_25 {
    margin-top: 25px;
}
.pl_42 {
    padding-left: 42px;
}
.Testimonial_row.row {
    margin-left: -11px;
    margin-right: -11px;
    width: calc(100% + 22px);
}
.Testimonial_row.row>* {
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 22px;
}
.explore_product_section.testimonial_section {
    padding: 65px 38px;
}
.testsimonial_col {
    padding: 20px 50px 55px 50px;
    background-color: var(--def_color);
}
.mt_28 {
    margin-top: 28px;
}
.mb_14 {
    margin-bottom: 14px;
}
.fitness_text {
    font-size: 67.778px;
    line-height: 85px;
    text-transform: capitalize;
}
section.your_fitness_section {
    padding: 60px 38px 50px 15px;
}
.fitness_left {
    width: 540px;
}
.fitness_rgt {
    width: calc(100% - 540px);
}
.fitness_para {
    font-size: 22px;
    line-height: 25px;
}
.our_story_section {
    padding: 81px 38px;
}
.play_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.our_story_section .container {
    background-color: var(--def_color);
}
.our_story_left  {
    width: 630px;
}
.our_story_right {
    width: calc(100% - 630px);
    padding: 57px;
}
.shop_more.take_quiz_btn {
    padding: 12px 30px;
}
.best_selling_pro .row > * {
    display: flex;
    align-items: center;
}
/******* home css - Ends ***********/

/******* footer css ***********/
.refer_footer {
    margin: 0 !important;
}
.fw_reg {
    font-weight: 400 !important;
}
.site-footer.refer_footer {
    background-color: var(--text_color);
    padding: 4px 80px 0 35px;
}
.sent_mail_input {
    background-color: #D9D9D9;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 45px;
    padding: 5px 50px 5px 15px;
}
.sent_mail_input::-webkit-input-placeholder {
    color: var(--pri_color);
}
.send_mail_link {
    position: absolute;
    right: 0;
    top: 0;
}
.mb_16 {
    margin-bottom: 16px;
}
.pl_10 {
    padding-left: 10px;
}
.add_sec>*:not(:last-child) {
    margin-bottom: 16px;
}
.location_icon {
    position: relative;
    top: 2px;
}
.mb_27 {
    margin-bottom: 27px;
}
.footer_menu li:not(:last-child) {
    margin-bottom: 7px;
}
.social_media_link>*:not(:last-child) {
    margin-right: 13px;
}
/******* footer css - Ends ***********/

/******* suplements css ***********/
.suplements_blk {
    padding: 45px 0;
}
.pro_breadcrumb li::before {
    display: none !important;
}
.pro_breadcrumb .breadcrumb-item+.breadcrumb-item {
    padding-left: 6px;
    color: #4E495D;
}
.sup_pro_row {
    margin-left: -14px;
    margin-right: -14px;
    width: calc(100% + 28px);
}
.sup_pro_row>* {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 28px;
}
.refer_produc_wrapper.sup_pro_col {
    margin: 0;
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 35px 20px;
}
a:hover {
    text-decoration: none;
}
a.text-underline:hover {
    text-decoration: underline;
}
.refer-img-wrapper {
    width: 148px;
}
.mb_4 {
    margin-bottom: 4px;
}
.mb_9 {
    margin-bottom: 9px;
}
.pl_3 {
    padding-left: 3px;
}
.shop_more.add_cart_btn {
    padding: 6px 13px;
}
.pl_7 {
    padding-left: 7px;
}
.pro_filter_sec {
    background-color: #4E495D;
    border-radius: 8px;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    padding: 20px;
    border: 1px solid;
}
.pro_filter_sec>*:not(:last-child) {
    margin-right: 31px;
}
.filter_select .btn-primary {
    background-color: transparent !important;
    border: none !important;
    padding: 0;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    font-size: 16px;
    line-height: 18px;
    box-shadow: none !important;
    display: flex;
    align-items: center;
}
.filter_select .dropdown-menu .dropdown-item {
    color: var(--pri_color);
    font-size: 16px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    line-height: 18px;
    padding: 5px 10px;
    background-color: transparent !important;
}
.filter_select .dropdown-menu {
    padding: 0;
}
.filter_select .dropdown-toggle::after {
    width: 7px;
    margin-left: 4px;
    height: 7px;
    background-image: url('././../images/refer_images/filter_dropdown.svg');
    background-repeat: no-repeat;
    background-position: center;
    border: none;
}
/******* suplements css - Ends ***********/

/******* rating css ***********/
.rate {
    flex-direction: row-reverse;
    justify-content: center;
}
.five_star label, .four_star label:nth-child(4), .four_star label:nth-child(6), .four_star label:nth-child(8), .four_star label:nth-child(10),
.three_star label:nth-child(6), .three_star label:nth-child(8), .three_star label:nth-child(10), .two_star label:nth-child(8), .two_star label:nth-child(10),
.one_star label:nth-child(10) {
    color: #FFD200 !important;    
}
.rate.disabled label {
    pointer-events: none;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float: right;
    width: 1em;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    font-size: 13px;
    margin: 0;
    line-height: 13px;
    color: #ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #FFD200;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #FFD200;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #FFD200;
}
/******* rating css - Ends ***********/

/******* product css  ***********/
.pro_left_blk {
    background: rgba(242, 242, 242, 0.5);
    padding: 40px 30px 80px 60px;
}
.mb_44 {
    margin-bottom: 44px;
}
.pro_breadcrumb.cus_breadcrumb li {
    font-size: 16px;
    color: #4E495D;
    line-height: 18px;
}
.pro_breadcrumb.cus_breadcrumb li a {
    color: var(--text_color);
    display: flex;
}
.pro_left_blk  .mkn-img-container {
    border: none;
    background: none;
    margin: 0 auto 69px auto;
}
.pro_left_blk .carousel .thumbs {
    justify-content: center;
    display: flex;
}
.pro_left_blk .carousel .thumbs li.thumb {
    width: 96px !important;
    height: 106px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    border: 1px solid transparent !important;
}
.pro_left_blk .carousel .thumbs li.thumb:not(:last-child) {
    margin-right: 10px !important;
}
.pro_left_blk .carousel .thumbs li.thumb.selected {
    background-color: var(--def_color);
    border-color: #DAD7E3;
}
.pro_left_blk .slider-wrapper.axis-horizontal {
    width: 227px;
}
.pro_rgt_blk {
    padding: 32px 50px;
}
.font_24 {
    font-size: 24px;
    line-height: 28px;  
}
.ml_13 {
    margin-left: 13px;
}
.ml_16 {
    margin-left: 16px;
}
.dis_span {
    background-color: var(--sec_color);
    border-radius: 4px;
    padding: 4px 11px;
}
.pl_11 {
    padding-left: 11px;
}
.detail_rate.rate > label {
    font-size: 22px;
    line-height: 17px;
    width: 17px;
    margin-left: 7px;
}
.mb_25 {
    margin-bottom: 25px;
}   
.pro_para  {
    line-height: 24px !important;
}
.pl_5  {
    padding-left: 5px;
}
.pro_des  li {
    margin-right: 24px;
    margin-bottom: 8px;
}
.choose_text {
    color: #4E495D;
}
.choose_flavour .swatch_block div label {
    margin-right: 0;
    background-color: var(--sec_color);
    border: none;
    border-radius: 4px;
    padding: 12px 21px;
    text-transform: capitalize;
    margin-bottom: 0;
}
.choose_flavour .swatch_block>div {
    margin-bottom: 13px;
    margin-right: 13px;
}
.choose_flavour .swatch_block div input {
    display: block !important;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
}
.choose_flavour .swatch_block div input:checked + label {
    background-color: rgba(34, 28, 53, 0.77);
    color: var(--def_color);
}
.pl_35 {
    padding-left: 35px;
}
.del_opt_div {
    background-color: var(--sec_color);
    border-radius: 4px;
    padding: 13px 17px;
}
.del_left {
    width: calc(100% - 14px);
    padding-right: 20px;
}
.quan_cart_blk 
 .refer_product_input_group {
    width: 140px;
    background: var(--def_color);
    border: 1px solid var(--pri_color);
    border-radius: 5px 0px 0px 5px;
    height: 45px;
}
.quan_cart_blk .refer_product_input_group .input-group-prepend button {
    background-color: transparent;
    padding: 5px 7;
    color: var(--pri_color);
    font-weight: 400 !important;
    box-shadow: none !important;
    outline: none !important;
    font-family: 'futura_reg' !important;
    font-weight: 400;
}
.quan_cart_blk .refer_product_input_group .form-control {
    height: 100%;
    border: none;
    font-family: 'futura_reg' !important;
    box-shadow: none !important;
    text-align: center !important;
    display: flex;
    justify-content: center;
    width: calc(100% - 60px);
    color: var(--pri_color);
    font-size: 22px;
    line-height: 25px;
}
.quan_cart_blk .cart_add_product_btn {
    width: calc(100% - 41px);
    height: 45px;
    border-radius: 0px 6px 6px 0px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    font-family: 'futura_bold' !important;
    background: var(--pri_color) !important;
}
.product_tab_section {
    background-color: var(--pri_color);
    padding: 50px 0 0 0; 
}
.product_tabs {
    border: none;
    justify-content: center;
    margin-bottom: 14px;
}
.product_tabs>li:not(:last-child) {
    margin-right: 50px;
}
.product_tabs>li .nav-link {
    margin-right: 50px;
    color: #B4B1BC;
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    font-family: 'futura_bold';
    padding: 0 0 5px 0!important;
    background-color: transparent !important;
    position: relative;
    border: none !important;
    outline: none !important;
}
.product_tabs>li .nav-link.active {
    background-color: transparent;
    border: none;
    color: var(--def_color);
}
.product_tabs>li .nav-link.active::after {
    content: "";
    display: block;
    width: 100%;
    height: 1.5px;
    background-color: var(--def_color);
    position: absolute;
    bottom: 0;
    left: 0;
}
.benefit_text {
    color: #D3D2D7;
}
.font_36 {
    font-size: 36px;
    line-height: 56px;
}
.mb_11 {
    margin-bottom: 11px;
}
.font_18 {
    font-size: 18px;
    line-height: 21px;
}
.pl_34 {
    padding-left: 34px;
}
.benefit_ul {
    width: fit-content;
}
.benefit_ul li {
    padding: 13px 0 10px 0;
    border-bottom: 1px solid #545454;
}
.ben_letf {
    width: 406px;
}
.ben_rgt {
    width: calc(100% - 406px);
    padding-left: 105px;
}
.mb_39 {
    margin-bottom: 39px;
}
.how_to_use_col {
    background-color: #302945;
    border-radius: 13px;
    padding: 22px 32px;
}
.how_one_text {
    font-weight: 700;
    font-size: 105px;
    line-height: 164px;
    color: #D3D2D7;
    opacity: 0.3;
    position: absolute;
    top: -24px;
    right: 6px;
    font-family: 'futura_bold';
    width: 78px;
    text-align: center;
}
.how_use_tab {
    padding-top: 47px;
    padding-bottom: 100px;
}
.howuse_heading {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}
.mb_19 {
    margin-bottom: 19px;
}
.mb_106 {
    margin-bottom: 109px;
}
.mb_15 {
    margin-bottom: 15px;
}
.sup_fact_left {
    width: 400px;
}
.cal_fiv {
    width: 212px;
    height: 212px;
    background-color: #302945;
    border-radius: 50%;
    position: absolute;
    right: -106px;
}
.cal_no {
    font-size: 55.89px;
    line-height: 64px;
}
.cal_text {
    font-size: 33.89px;
    line-height: 39px;
}
.sup_fact_rgt {
    background-color: #302945;
    border-radius: 8px;
    padding: 43px 25px;
    width: 550px;
}
.suplement_fact_div {
    padding: 65px 0 71px 0;
}
.pr_5 {
    padding-right: 5px;
}
.pb_15 {
    padding-bottom: 15px;
}
.fact_btm_bor {
    border-bottom: 3px solid #3B3844;
}
.mb_31 {
    margin-bottom: 31px;
}
.pb_11 {
    padding-bottom: 11px;
}
.pl_41 {
    padding-left: 41px;
}
.mb_32 {
    margin-bottom: 32px;
}
.ingredient_text {
    color: #9592A0;
}
.ingredient_blk p.font_16 {
    line-height: 24px !important;
}
.ingredient_blk .row {
    width: calc(100% + 50px);
    margin-left: -25px;
    margin-right: -25px;
}
.ingredient_blk .row>* {
    padding-left: 25px;
    padding-right: 25px;
}
.ingedient_col {
    background-color: #302945;
    border-radius: 4px;
    padding: 32px;
}
.ingredient_blk{
    padding: 51px 0 71px 0;
}
.mb_38 {
    margin-bottom: 38px;
}
.faq_section {
    padding: 67px 0;
}
.faq_section .container {
    width: 860px;
}
.faq_section  .accordion-header {
    margin: 0;
}
.faq_section .accordion-header .accordion-button.collapsed {
    background-color: transparent;
    padding-bottom: 13px;
    padding-top: 13px;
}
.faq_section .accordion-header .accordion-button {
    background-color: var(--def_color);
    width: 100%;
    border: none;
    outline: none;
    padding-top: 26px;
    padding-left: 29px;
    padding-right: 57px;
    padding-bottom: 0;
}
.faq_section .accordion-header .accordion-button .plus_icon, .faq_section .accordion-header .accordion-button.collapsed .minus_icon {
    display: none;
} 
.faq_section .accordion-header .accordion-button.collapsed .plus_icon {
    display: block;
}
.faq_section  .accordion-item {
    margin-bottom: 13px;
}
.faq_section .accordion-collapse {
    padding-left: 29px;
    padding-top: 6px;
    padding-right: 100px;
    padding-bottom: 33px;
}
.faq_section .accordion-collapse.show {
    background-color: var(--def_color);
}
.faq_section .accordion-collapse .font_16 {
    line-height: 24px !important;
}
.like_product_section {
    padding: 60px 0 80px 0;
}
.like_pro_para {
    width: 48%;
}
.mb_30 {
    margin-bottom: 30px;
}
.mb_45 {
    margin-bottom: 45px;
}
.white_bg {
    background-color: var(--def_color);
}
.overall_rating {
    padding: 24px 24px 50px 24px;
}
.mb_52 {
    margin-bottom: 52px;
}
.mb_11 {
    margin-bottom: 11px;
}
.pro_img {
    width: 180px;
    margin: 0 auto 22px auto;
    height: 273px;
}
.pro_img>* {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}
.pro_carousel .owl-carousel {
    position: static;
}
.pro_carousel .owl-prev, .pro_carousel .owl-next {
    margin: 0 !important;
    background-color: var(--pri_color) !important;
    color: #fff !important;
    height: 93px;
    width: 37px;
    border-radius: 8px 0 0 8px !important;
    position: absolute;
    font-size: 46px !important;
    line-height: 46px !important;
}
body .pro_carousel .owl-prev {
    border-radius: 0 8px 8px  0 !important;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
}
body .pro_carousel .owl-next {
    border-radius: 0 8px 8px  0 !important;
    right: 0;
    transform: translateY(-50%);
    top: 50%;
}
.review_rating_section {
    padding: 53px 0;
}
.like_pro_para.font_16 {
    line-height: 24px !important;
}
.rate.overall_rate label {
    font-size: 31px;
    line-height: 28px;
    margin: 0 6px;
}
.most_recent_sel {
    background-color: var(--def_color);
    border: 1px solid var(--pri_color);
    border-radius: 6px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    padding: 8px 35px 8px 14px;
    appearance: none;
    font-family: 'futura_reg' !important;
    background-image: url('./../images/refer_images/select_dropdown.svg');
    background-position: calc(100% - 15px);
    background-repeat: no-repeat;
}
.shop_more.take_quiz_btn.write_review_btn {
    padding: 8px 30px;
}
.mb_23 {
    margin-bottom: 23px;
}
.wri_review_blk {
    padding: 40px 32px;
    margin-bottom: 24px;
}
.mb_6 {
    margin-bottom: 6px;
}
.reviewer_img img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
}
.reviewer_img {
    width: 64px;
}
.pl_15{
    padding-left: 15px;
}
.reviewer_det {
    width: calc(100%  - 64px);
}
.rev_left {
    width: 25%;
}
.w-60 {
    width: 60% !important;
}
.rate.reviewer_rate label {
    font-size: 16px;
    line-height: 15px;
    margin: 0 3px;
}
.mt_39 {
    margin-top: 39px;
}
/******* product css - Ends ***********/

/******* cart css ***********/
.cart_page_container {
    padding: 32px 0;
}
.cart_breadcrubmb .font_16 {
    line-height: 24px !important;
}
.pro_breadcrumb.cus_breadcrumb.cart_breadcrubmb li a {
    color: #4E495D;
}
.line_height_25 {
    line-height: 25px !important;
}
.red_text, a.red_text:hover {
    color: #FF5959 !important; 
}
.cart_row {
    width: calc(100% + 22px);
    margin-left: -11px;
    margin-right: -11px;
}
.cart_row>* {
    padding-left: 11px;
    padding-right: 11px;
}
.cart_pro_list {
    background-color: var(--sec_color);
    margin-bottom: 18px;
    border-radius: 8px;
    padding: 15px;
}
.cart_pro_list_left  {
    width: 60%;
    padding-right: 20px;
}
.cart_quantity.quan_cart_blk {
    width: 141px;
}
.cart_quantity.quan_cart_blk .refer_product_input_group {
    border-radius: 5px;
    height: 29px;
    width: 100%;
}
.cart_quantity.quan_cart_blk .refer_product_input_group .form-control {
    font-size: 18px;
    line-height: 21px;
}
.cart_total {
    width: calc(40% - 140px);
    padding-left: 20px;
    text-align: right;
}
.cart_pro_img img {
    width: 70px;
}
.pl_13 {
    padding-left: 13px;
}
.cart_pro_det  {
    width: calc(100% - 70px);
}
.cart_rgt_blk {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 25px;
}
.coupon_input {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    font-size: 14px;
    line-height: 16px;
    width: calc(100% - 81px);
    height: 32px;
    margin-right: 14px;
    padding: 5px 10px;
    outline: none;
}
.coupon_apply {
    background-color: rgba(34, 28, 53, 0.5);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: var(--def_color);
    font-family: 'futura_reg' !important;
    padding: 6.5px 13px;    
    cursor: pointer;
}
.total_span {
    width: 60%;
    padding-right: 10px;
}
.price_span {
    padding-left: 10px;
    width: 40%;
}
.mb_3 {
    margin-bottom: 3px;
}
.cart_page_container {
    min-height: calc(100vh - 90px);
}
/******* cart css - Ends ***********/

/******* checkout css ***********/
.checkout_left_blk {
    padding: 30px 60px;
}
.font_11{
    font-size: 11px;
    line-height: 13px;
}
.checkout_ul {
    background-color: #D9D9D9;
    border-radius: 50px;
}
.checkout_ul li {
    width: 33.33%;
    border-radius: 50px;
    padding: 12.5px;
}
.checkout_round {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}
.checkout_ul li.active {
    background-color: var(--pri_color);
}
.checkout_ul li.active .font_16 {
    color: var(--def_color);
}
.height_42 {
    height: 42px !important;
}
.checkout_label label {
    font-size: 16px;
    line-height: 18px;
}
.mt_15 {
    margin-top: 15px;
}
.mt_11 {
    margin-top: 11px;
}
.checkbox_label input {
    display: none;
}
.checkbox_label .checbox {
    border: 1px solid var(--pri_color);
    border-radius: 1px;
    width: 11px;
    display: block;
    height: 11px;
    position: relative;
}
.checkbox_label.radio_label .checbox {
    border-radius: 50%;
}
.form_input_blk .form-control:disabled {
    background-color: var(--def_color);
}
.pl_6 {
    padding-left: 6px;
}
.checkbox_label input:checked + .checbox {
    background-color: var(--pri_color);
}
.checkbox_label input:checked + .checbox::after {
   content: '';
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%) rotate(-52deg);
    width: 5px;
    height: 2px;
    border-left: 1px solid var(--def_color);
    border-bottom: 1px solid var(--def_color);
}
.checkbox_label.radio_label input:checked + .checbox::after {
   content: '';
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    background-color: var(--def_color);
    border-radius: 50%;
}
.mb_12 {
    margin-bottom: 12px;
}
.w-20  {
    width: 20% !important;
}
.mr_32 {
    margin-right: 32px !important;
}
.checkout_rgt_blk {
    padding: 115px 55px;
    background-color: var(--sec_color);
}
.cart_summary_blk  {
    padding: 25px;
    border-radius: 8px;
}
.cart_pro_su {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 14px;
    margin-bottom: 8px;
}
.mt_66 {
    margin-top: 66px;
}
.shop_more.cont_ship_btn:disabled {
    background: rgba(34, 28, 53, 0.3);
    color: rgba(255, 255, 255, 0.3);
}
.shop_more.take_quiz_btn.cont_ship_btn {
    padding: 13.5px 30px;
    border: none;
}
.checkout_page, .checkout_page>div>.row {
    min-height: 100vh;
}
.mt_43 {
    margin-top: 43px;
}
.shipping_blk {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
}
.shipping_info {
    padding: 13px;
}
.shipping_info.border_btm {
    border-bottom: 1px solid var(--pri_color);
}
.checkout_ul li.active.selected {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.checkout_ul li.active.selected + .active {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.mb_48 {
    margin-bottom: 48px;
}
.shipping_me_div {
    background-color: var(--def_color);
    border: 2px solid var(--pri_color);
    border-radius: 4px;
    padding: 10px 13px;
}
.shipping_left {
    width: 80%;
}
.shipping_rgt {
    width: 20%;
}
.contact_label {
    width: 100px;
}
.contact_label + * {
    width: calc(100% - 100px);
    padding-left: 10px;
}
.pay_lk {
    background-color: var(--def_color);
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    padding: 21px 0;
}
.pay_lk .pay_header {
    border-bottom: 0.5px solid var(--pri_color);
    padding-bottom: 15px;
    padding-left: 19px;
    padding-right: 19px;
}
.cpay_body.form_input_blk {
    padding: 18px 19px 0 19px;
}
.ml_6 {
    margin-left: 6px;
}
.payment_upi {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 14px;
    height: 100%;
}
.payment_upi span.checbox + img {
    width: calc(100% - 17px);
}
.upi_row {
    width: calc(100% + 22px);
    margin-left: -11px;
    margin-right: -11px;
}
.upi_row>* {
    padding-left: 11px;
    padding-right: 11px;
}
.order_confirmed_page {
    min-height: calc(100vh - 90px);
    background-color: #f5f5f5;
}
.line_height_38 {
    line-height: 38px !important;
}
.mb_58 {
    margin-bottom: 58px;
}
.pl_9 {
    padding-left: 9px;
}
.line_height_25{
    line-height: 25px !important;
}
.payment_me {
    width: 130px;
    padding-right: 10px;
}
.credit_span {
    width: calc(100% - 130px);
}
.payment_me::after {
    content: ":";
    position: absolute;
    right: 4px;
}
/******* checkout css - Ends ***********/

/******* order confirmed css ***********/
.order_confirmed_page {
    padding: 58px 0;
}
.order_confirmed_page .container {
    width: 783px;
}
.thankyou_page {
    border-radius: 8px;
}
.thank_you_header {
    padding: 55px 45px 45px 45px;
}
.cart_bor_btm {
    border-bottom: 0.5px dashed var(--text_color);
}
.thank_you_body {
    padding: 35px 45px;
}
.pro_cart_det.payemnt_total_pri {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 15px;
}
.thank_btn {
    padding: 23px 45px;
}
.mr_38 {
    margin-right: 38px;
}
.mt_26 {
    margin-top: 26px;
}
.cart_pro_blk .cart_pro_img {
    width: 36px;
}
.cart_pro_blk .cart_pro_det {
    width: calc(100% - 36px);
}
.cart_pro_blk .cart_pro_img img {
    width: 36px;
}
.cart_pro_blk .cart_pro_su {
    margin: 0;
}
.cart_pro_blk .cart_pro_su:not(:last-child) {
    margin-bottom: 18px;
}
.right_blk_menu .dropdown-menu.show {
    right: 0 !important;
    left: auto !important;
    transform: inherit !important;
    top: 30px !important;
}
.pl_16 {
    padding-left: 16px;
}
/******* order confirmed css - Ends ***********/

/******* 28/03/2023 css ***********/
.shop_menu_li img {
    width: 100%;
    height: 92px;
    object-fit: contain;
}
/******* 28/03/2023 css - Ends ***********/

iframe {
    display: block;
}

iframe#attentive_overlay,
iframe#attentive_creative {
    display: block !important;
}
.cancel {
    padding-left:  428px !important;
}
