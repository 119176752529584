@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100..900&display=swap');
@font-face {
    font-family: 'El_messari_reg';
    src: url('../fonts/El_Messiri/ElMessiri-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'El_messari_med';
    src: url('../fonts/El_Messiri/ElMessiri-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'El_messari_semi';
    src: url('../fonts/El_Messiri/ElMessiri-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'El_messari_bold';
    src: url('../fonts/El_Messiri/ElMessiri-Bold.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'arimo_reg';
    src: url('../fonts/Arimo/Arimo-Regular.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'arimo_med';
    src: url('../fonts/Arimo/Arimo-Medium.ttf');
    font-weight: 500;
}
@font-face {
    font-family: 'arimo_semi';
    src: url('../fonts/Arimo/Arimo-SemiBold.ttf');
    font-weight: 600;
}
@font-face {
    font-family: 'arimo_bold';
    src: url('../fonts/Arimo/Arimo-Bold.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'futura_reg';
    src: url('../fonts/futura/Futura_Book_font.ttf');
    font-weight: 400;
}
@font-face {
    font-family: 'futura_light';
    src: url('../fonts/futura/futura_light_bt.ttf');
    font-weight: 300;
}
@font-face {
    font-family: 'futura_bold';
    src: url('../fonts/futura/FuturaBoldfont.ttf');
    font-weight: 700;
}
@font-face {
    font-family: 'futura_med';
    src: url('../fonts/futura/futura_medium_bt.ttf');
    font-weight: 500;
}
body {
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
.fw_el_reg {
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.fw_el_med {
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.fw_el_semi {
    font-weight: 500 !important;
    font-family: 'futura_med';
}
.fw_el_bold {
    font-weight: 700 !important;
    font-family: 'futura_bold';
}
.fw_ar_reg {
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
.fw_ar_med {
    font-weight: 500 !important;
    font-family: 'futura_med';
}
.fw_ar_semi {
    font-weight: 500 !important;
    font-family: 'futura_med' !important;
}
.fw_ar_bold {
    font-weight: 700 !important;
    font-family: 'futura_bold' !important;
}
/******** customer header css *****************/
.header_blk {
    background-color: var(--def_color);
    margin: 0;
    box-shadow: 0px 4px 4px 0px #00000040;
}
.header_blk .hide_small_screen .container-fluid {
    padding: 0 50px 0 50px;
}
.header_blk .hide_small_screen .container-fluid .logo-wrapper {
    padding: 0;
}
.let_spa_7px {
    letter-spacing: 0.07em;
}
.main_menu_blk ul li a {
    color: var(--text_color);
    font-size: 20px;
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 700;
    line-height: 36px;
}
.main_menu_blk ul li a:hover {
    text-decoration: none;
}
.main_menu_blk ul li a span {
    display: block;
    padding-right: 10px;
}
.main_menu_blk ul li:not(:last-child) {
    margin-right: 29px;
}
.right_blk_menu li:not(:first-child) {
    margin-left: 20px;
}
/******** customer header css - Ends *****************/

/******** customer dashboard css *****************/
.customer_dashboard_div {
    min-height: calc(100vh - 90px);
    background-color: #F5F5F5;
    padding: 52px 0;
    height: calc(100% - 90px);
}
.customer_menu {
    margin-bottom: 35px;
}
.customer_menu li a {
    font-weight: 700;
    font-size: 16px;
    font-family: 'futura_bold';
    line-height: 18px;
    color: #4E495D;
}
.customer_menu li:not(:last-child) {
    margin-right: 30px;
}
.customer_menu li a {
    position: relative;
    padding-bottom: 9.5px;
}
.customer_menu li a:hover {
    text-decoration: none;
}
.customer_menu li a.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.5px;
    background-color: var(--text_color);
}
.customer_dashboard_div + footer.site-footer {
    display: none;
}
/******** customer dashboard css - Ends *****************/

/******** customer dashboard css  *****************/
.text_black, .text_black:hover {
    color: var(--pri_color);
}
.font_16 {
    font-size: 16px;
    line-height: 18px;
}
.mb_5 {
    margin-bottom: 5px !important;
}
.mb_18 {
    margin-bottom: 18px !important;
}
.m-0 {
    margin: 0 !important;
}
.font_11 {
    font-size: 11px;
    line-height: 16px;
}
.let_spa_53 {
    letter-spacing: 0.53em;
}
.card_blk .card_row.row {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
}
.card_blk .card_row.row>* {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 24px;
}
.card_col.card {
    border: none;
    border-radius: 8px;
    padding: 25px;
}
.card_blk .card_row.row>*:nth-child(1n) .card {
    background: linear-gradient(107.28deg, var(--sec_color) 0.89%, #C8C1DE 100%);
}   
.text_white, .text_white:hover {
    color: #fff !important;
}
.card_col.card .active, .status_active {
    background: #768E87;
    width: fit-content;
    border-radius: 37px;
    padding: 5px 17px;
    text-transform: capitalize;
}
.let_spa_2 {
    letter-spacing: 0.02em;
}
.mt_42 {
    margin-top: 42px;
}
.add_new_btn {
    background: var(--pri_color);
    border-radius: 8px;
    padding: 12px 20px;
    width: 230px;
    justify-content: center;
}
.card_blk {
    padding-top: 9px;
}
.cursor-pointer {
    cursor: pointer;
}
.add_new_card {
    background-color: var(--def_color);
    border-radius: 8px;
    padding: 30px;
}
.form_input_blk label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 6px;
    color: var(--pri_color);
    font-weight: 400 !important;
    font-family: 'futura_reg';
}
.form_input_blk .form-control {
    border: 0.5px solid var(--pri_color);
    border-radius: 4px;
    padding: 8px 11px;
    font-weight: 400;
    font-size: 16px;
    color: var(--pri_color);
    line-height: 18px;
    font-family: 'futura_reg';
    height: 42px;
}
.text_default {
    color: rgba(34, 28, 53, 0.5);
    opacity: 50%;
}
.font_12 {
    font-size: 12px !important;
    line-height: 16px !important;
}
.font_italic {
    font-style: italic;
}
.mt_30 {
    margin-top: 30px;
}
.mb_21 {
    margin-bottom: 21px;
}
.form_input_blk .form-control input {
    border: none;
    font-size: 16px;
    line-height: 18px;
    padding: 0 10px 0 0;
    outline: none;
    width: 150px;
}
.form_input_blk .form-control input.last_name_input {
    padding: 0 10px 0 10px;
    border-left: 0.3px solid #000000;;
}
.mt_66 {
    margin-top: 66px;
}
.form_input_blk .form-group {
    margin-bottom: 16px !important;
}
.form_input_blk .required {
    color: var(--pri_color);
}
.pt_9 {
    padding-top: 9px;
}
.account_row {
    margin-left: -12px;
    margin-right: -12px;
    width: calc(100% + 24px);
}
.account_row>* {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 24px;
}
.mt_80 {
    margin-top: 80px !important;
}
/******** customer dashboard css - Ends *****************/

/******** customer address css - Ends *****************/
.font_14 {
    font-size: 14px;
    line-height: 16px;
}
.mb_13 {
    margin-bottom: 13px;
}
.page-content.entry-content .address-card.card p.m-0 {
    margin: 0 !important;
}
.text_underline,a.text_underline:hover {
    text-decoration: underline !important;
}
.address-card.card {
    border: none;
    border-radius: 8px;
}
.address-card.card .card_header {
    padding: 20px;
}
.address-card .card_footer {
    background: #768E87;
    border-radius: 0 0 8px 8px;
    padding: 10px 20px;
}
.pl_4 {
    padding-left: 4px;
}
.ml_10 {
    margin-left: 10px;
}
.phone_span {
    width: 70px;
    padding-right: 10px;
}
.phone_span::after {
    content: ":";
    position: absolute;
    top: -1px;
    right: 4px
}
.card_footer.set_def_card_foot {
    background-color: var(--def_color);
    padding-bottom: 18px;
    padding-top: 0;
}
/******** customer address css - Ends *****************/

/******** customer subscription css *****************/
.font_22 {
    font-size: 22px;
    line-height: 25px;  
}
.mb_43 {
    margin-bottom: 43px;
}
.mb_7 {
    margin-bottom: 7px;
}
.mb_29 {
    margin-bottom: 29px;
}
.subscrip_progress.progress {
    height: 15px;
    background-color: var(--sec_color);
    border-radius: 16px;
}
.subscrip_progress.progress .progress-bar {
    background-color: #768E87;
    border-radius: 16px;
}
.sub_current_div {
    background: var(--def_color);
    box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: 29px 48px;
    margin-bottom: 50px;
}
.w-22 {
    width: 22% !important;
}
.text_gray {
    color: #4E495D;
}
.mb_26 {
    margin-bottom: 26px;
}
.invoice_table th {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
    padding-bottom: 12px;
}
.invoice_table  tbody {
    border-bottom: 9px solid whitesmoke;
}
.invoice_table tbody td {
    background-color: var(--def_color);
    padding: 10px 17px;
}
.invoice_table tbody td:first-child {
   border-radius: 8px 0 0 8px;
}
.invoice_table tbody td:last-child {
   border-radius: 0 8px 8px 0;
}
.sub_name {
    width: 20%;
}
.order_type, .sub_status {
    width: 15%;
}
.sub_date {
    width: 20%;
}
.sub_total, .sub_active {
    width: 15%;
}
.pb_9 {
    padding-bottom: 9px;
}
.subscription_ul.subscription_body {
    padding: 23px 0px;
    background: var(--def_color);
    border-radius: 8px;
    margin-bottom: 9px;
}
.subscription_ul.subscription_body li:first-child {
    padding-left: 17px;
}
.pr_17 {
    padding-right: 17px;
}
.w-50 {
    width: 50%;
}
/******** customer subscription css - Ends *****************/

/******** customer orders css *****************/
.ml_20 {
    margin-left: 20px;
}
.ml_18 {
    margin-left: 18px;
}
.mb_0 {
    margin-bottom: 0 !important;
}
.ml_23 {
    margin-left: 23px;
}
.order_accordatio {
    padding: 30px 37px;
    background: var(--def_color);
    border-radius: 8px;
    margin-bottom: 28px;
}
.order_accordatio .order_acc_header {
    padding-bottom: 22px;
}
.items_text {
    color: rgba(34, 28, 53, 0.5);
}
.mb_8 {
    margin-bottom: 8px;
}
.order_pro_detail {
    background: var(--sec_color);
    border-radius: 8px;
    padding: 11px;
    margin-bottom: 8px;
}
.product_image {
    width: 38px;
}
.product_image + * {
    width: calc(100% - 38px);
}
.pr_10 {
    padding-right: 10px;
}
.order_acc_body {
    padding: 2px 0 44px 0;
}
.status_active.cancelled {
    background-color: #66435A;
}
.status_active.failed {
    background-color: #FF3838;
}
.status_active.completed {
    background-color: #56F000;
}
.status_active.onhold {
    background-color: #FFB302;
}
.order_acc_footer .accordion-button {
    padding: 0;
    display: flex;
    background-color: transparent;
    outline: none;
    border: none;
}
/******** customer orders css - Ends *****************/

/******** customer orders detail  css *****************/
.font_26 {
    font-size: 26px;
    line-height: 30px;
}
.mb_4 {
    margin-bottom: 4px;
}
.pl_10 {
    padding-left: 10px;
}
.mb_25 {
    margin-bottom: 25px;
}
.order_det_total {
    background-color: var(--sec_color);
    border-radius: 8px;
    padding: 20px 15px;
    margin-bottom: 9px;
}
.order_det_blk {
    padding: 28px 38px;
    border-radius: 8px;
}
/******** customer orders detail  css - Ends *****************/

/******** customer subscription detail  css *****************/
.mr_36 {
    margin-right: 36px;
}
.mb_32 {
    margin-bottom: 32px;
}
.auto_reniew_blk {
    padding: 23px;
    border-radius: 8px;
}
.switch_label input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
    appearance: none;
    cursor: pointer;
}
.switch_span {
    width: 40px;
    height: 18px;
    background: linear-gradient(0deg, var(--sec_color), var(--sec_color)), linear-gradient(0deg, var(--sec_color), var(--sec_color)), var(--sec_color);
    border-radius: 37px;
    position: relative;
    cursor: pointer;
}
.switch_span::after {
    display: block;
    background: linear-gradient(0deg, #768E87, #768E87), linear-gradient(0deg, var(--sec_color), var(--sec_color)), var(--sec_color);
    width: 18px;
    height: 18px;
    border-radius: 37px;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    transition: 0.5s ease-in-out;
}
input:checked 
 + .switch_span::after {
    left: 21px;
}
.sub_billing_blk {
    padding: 25px;
    border-radius: 8px;
}
.sub_btm_det li:not(:last-child) {
    margin-right: 42px;
}
.mt_19 {
    margin-top: 19px;
}
.border_radius_50 {
    border-radius: 50px !important;
}
/******** customer subscription detail  css - Ends *****************/

/******** dashboard  css *****************/
.dashboard_row {
    width: calc(100% + 28px);
    margin-left: -14px;
    margin-right: -14px;
}
.dashboard_row>* {
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 28px;
}
.dashboar_col {
    background-color: var(--def_color);
    border-radius: 8px;
    padding: 20px 28px;
}
.order_on_div, .order_status_div {
    width: 20%;
}
.dashboar_col .right_pro {
    width: 10%;
}
.order_acc_body.das_order_acc_body .order_pro_detail {
    margin: 0;
}
.order_acc_body.das_order_acc_body .order_pro_detail:not(:last-child) {
    margin: 0 0 18px 0;
}
/******** dashboard  css - Ends *****************/
/******* header sub menu css ***********/
.mt_17 {
    margin-top: 17px;
}
.header_sub_menu .dropdown-menu {
    width: 100% !important;
    min-width: inherit;
    left: 0 !important;
    transform: inherit !important;
    top: 83px !important;
    border: none !important;
    border-radius: 0;
    background-color: var(--def_color);
    box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.35);
    padding: 50px 0 50px 0;
}
.shop_menu_li {
    background-color: #2E2645;
    border-radius: 5px;
    padding: 18px 5px;
}
.shop_menurow>* {
    flex: inherit;
    padding-left: 25px;
    padding-right: 25px;
    max-width: inherit;
    padding-bottom: 42px;
}
.shop_menurow {
    margin-left: -25px;
    margin-right: -25px;
    width: calc(100% + 50px);
}
.main_menu_blk ul.menu>li.show>a {
    position: relative;
}
.main_menu_blk ul.menu>li.show>a::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: var(--def_color);
    position: absolute;
    bottom: -4px;
    left: 0;
}
.main_menu_blk>ul>li>a >span {
    text-transform: uppercase;
}
.right_blk_menu .dropdown-menu>a {
    padding: 8px 15px;
    display: flex;
}
.right_blk_menu .dropdown-menu {
    background: var(--def_color);
    box-shadow: 0px 15px 26px rgba(0, 0, 0, 0.35);
    border-radius: 8px;
}
.mt_27 {
    margin-top: 27px;
}
.shop_all_link {
    padding: 6px 0;
    border-top: 1px solid var(--text_color);
    border-bottom: 1px solid var(--text_color);
}
.main_menu_blk ul li a.shop_all_link {
    font-size: 20px;
    line-height: 30px;
}
.user_login {
    margin: 50px auto;
}
/******* header sub menu css - Ends ***********/

/******* left sub menu css ***********/
.cus_dash_row  .left_menu {
    border: none;
    padding: 0;
}
.cus_dah_left {
    width: 275px;
    flex: inherit;
    max-width: inherit;
}
.cus_dash_rgt {
    width: calc(100% - 275px);
    flex: inherit;
    max-width: inherit;
    padding-left: 35px;
}
.cus_dash_row .left_menu>ul {
    background-color: var(--def_color);
    padding: 25px 0;
    border-radius: 8px;
}
.cus_dash_row .left_menu .active_icon, .cus_dash_row .left_menu>ul>li>a.active .inactive_icon {
    display: none;
}
.cus_dash_row .left_menu>ul>li>a.active .active_icon {
    display: block;
}
.cus_dash_row .left_menu>ul>li {
    border: none;
    margin: 0;
    padding: 0;
}
.cus_dash_row .left_menu>ul>li>a {
    font-weight: 700 !important;
    font-family: 'futura_bold' !important;
    font-size: 16px;
    line-height: 18px;
    color: var(--pri_color);
    display: flex;
    align-items: center;
    padding: 14px 25px;
    border-radius: 8px;
}
.cus_dash_row .left_menu>ul>li>a>img {
    margin-right: 9px;
}
.cus_dash_row .left_menu>ul>li>a.active {
    background: var(--pri_color);
    color: var(--def_color);
}
.dashboar_col {
    height: 100%;
}
/******* left sub menu css - Ends ***********/

/******* status bg css  ***********/
.status_active.processing {
    background-color: #56813d;
}   
.status_active.pending {
    background-color: #a33838;
}
/******* status bg css - Ends ***********/

/******* subscription table css  ***********/
.subscribtion_table th {
    padding-bottom: 7px;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
}
.subscribtion_table th:last-child, .subscribtion_table td:last-child {
    text-align: right;
}
.subscribtion_table {
    border-collapse: separate;
    border-spacing: 0 9px;
}
.subscribtion_table tr td {
    background-color: var(--def_color);
    padding: 20px 0;
    font-weight: 400;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.02em;
    font-family: 'futura_reg' !important;
}
.subscribtion_table tr td:first-child {
    border-radius: 8px 0 0 8px;
    padding-left: 17px;
}
.subscribtion_table tr td:last-child {
    border-radius: 0 8px 8px 0;
    padding-right: 17px;
}
/******* subscription table css - Ends ***********/

/******* subscription table css ***********/
.customer_dash_full_view , .customer_dash_full_view .container-fluid {
    padding: 0;
}
.customer_dash_full_view .container-fluid>* {
    margin: 0;
    min-height: calc(100vh - 90px);
}
.customer_dash_full_view .cus_dash_row .left_menu>ul, .customer_dash_full_view  .cus_dash_row .left_menu>ul>li>a {
    border-radius: 0;
}
.customer_dash_full_view .cus_dah_left {
    padding: 0;
    background-color: var(--def_color);
}
.customer_dash_full_view .cus_dash_rgt {
    padding: 40px;
}
/******* subscription table css - Ends ***********/

/******* order table css ***********/
.table_search .search_form input {
    border-color: var(--def_color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #252733;
    font-family: 'futura_bold' !important;
    padding: 10px 10px 10px 31px;
}
.table_search .search_form .search_icon {
    top: 11px;
    left: 11px;
    width: 15px;
    height: 15px;
    background-color: transparent;
}
.table_search_select .form-control {
    width: 130px !important;
    border-radius: 45px;
    border-color: var(--def_color);
    margin: 0 0 0 10px !important;
    appearance: none;
    color: rgba(34, 28, 53, 0.5);
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    font-family: 'futura_bold' !important;
    padding: 10px 30px 10px 10px;
    background-image: url('../images/customer_images/order_dropdown.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 15px);
}
ul.pagination.cus_pagination {
    margin-top: 20px;
}
/******* order table css - Ends ***********/

/******* dashboard css ***********/
.pro_carousel.browsing_his_carousel {
    width: calc(100% + 40px);
}
.pro_carousel.browsing_his_carousel .owl-prev, .pro_carousel.browsing_his_carousel .owl-next {
    border-radius: 8px !important;
}
.pro_carousel .owl-prev, .pro_carousel .owl-next {
    background: var(--pri_color) !important;
}
.mt_65 {
    margin-top: 65px;
}
/******* dashboard css - Ends ***********/

/******* Login css ***********/
.login_container {
    background-color: var(--sec_color);
    min-height: calc(100vh - 90px);
}
.login_container 
 .user_login {
    width: 450px;
}
/******* Login css - Ends ***********/

/******* contact css ***********/
.contact_container {
    padding: 40px 0;
}
.contact_cppatcha .form-control {
    width: 200px;
    margin: 0 20px;
}
.captcha_container.contact_cppatcha h2 {
    border-radius: 4px;
    border-color: var(--text_color);
    font-weight: 500 !important;
    font-family: 'futura_med';
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.terms_container 
 .terms_and_condition p, .terms_container 
 .terms_and_condition span {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px !important;
    font-family: 'futura_reg' !important;
    font-weight: 400;
    display: block;
    color: var(--pri_color) !important;
    text-transform: lowercase;
}
.terms_container 
.terms_and_condition h4  {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 8px !important;
    font-family: 'futura_bold' !important;
    font-weight: 700;
    display: block;
    color: var(--pri_color) !important;
}
.terms_container 
 .terms_and_condition p::first-letter, .terms_container 
 .terms_and_condition span::first-letter {
    text-transform: uppercase;
}
.terms_container 
 .terms_and_condition p a {
    text-decoration: underline;
 }
.terms_container .terms_and_condition p strong {
    font-weight: 700;
    display: inline;
    margin-top: 15px;
    font-family: 'futura_bold' !important;
}
.terms_container .terms_and_condition li, .terms_container .terms_and_condition li a {
    font-size: 16px;
    line-height: 22px;
    font-family: 'futura_reg' !important;
    color: var(--pri_color);
}
.form_input_blk textarea.form-control {
    height: 80px;
}
button#refresh {
    opacity: 50%;
}
.social_media_link a {
    display: flex;
}
/******* contact css - Ends ***********/

/******* contact css ***********/ 
body .error404 .subtitle {
    font-size: 20px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
    line-height: 22px;
}
body .page-content.entry-content p {
    font-size: 16px;
    line-height: 22px;
    font-weight: 400 !important;
    font-family: 'futura_reg' !important;
}
body .error404 .page-title {
    font-weight: 700 !important;
    font-family: 'futura_bold' !important;
}
/******* contact css - Ends ***********/

/******** css changes on 27/03/2023 page css ***********/
.table_search_select 
 .search_input_btn {
    width: 38px !important;
    height: 38px;
    background-color: var(--def_color);
    border-radius: 4px;
    border: 1px solid var(--def_color);
    background-image: url('../images/customer_images/header_search_icon.svg');
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}
.modal-content .col-md-12 {
    width: 100%;
}
/******** css changes on 27/03/2023 page css - Ends ***********/

/******** css changes on 08/08/2023 page css ***********/
#front-chat-iframe {
    display: block;
}
.front-chat-launcher {
    display: none;
}
/******** css changes on 08/08/2023 page css - Ends ***********/

/******** css changes on 08/08/2023 page css ***********/
.shop_menu>*:not(:last-child) {
    padding-bottom: 20px;
}
/******** css changes on 08/08/2023 page css - Ends ***********/

/******** new style Header and Footer css ***********/
.fw_inter_semi {
    font-family: "Inter", sans-serif;
    font-weight: 600;
}
.fw_inter_med {
    font-family: "Inter", sans-serif;
    font-weight: 500;
}
.fw_inter_reg {
    font-family: "Inter", sans-serif;
    font-weight: 400;
}
.fw_inter_bold {
    font-family: "Inter", sans-serif;
    font-weight: 700;
}
.barlow-semi-condensed-thin {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 100;
}
.barlow-semi-condensed-extralight {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 200;
}
.barlow-semi-condensed-light {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 300;
}
.barlow-semi-condensed-regular {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 400;
}
.barlow-semi-condensed-medium {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 500;
}
.barlow-semi-condensed-semibold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 600;
}
.barlow-semi-condensed-bold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 700;
}
.barlow-semi-condensed-extrabold {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 800;
}
.barlow-semi-condensed-black {
    font-family: "Barlow Semi Condensed", sans-serif;
    font-weight: 900;
}
.header_search_blk .form-control {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    height: 40px;
    font-family: 'futura_reg', sans-serif;
    padding-left: 41px;
}
.header_search_blk {
    width: 196px;
}
.header_search_blk img {
    top: 9px;
    left: 7px;
}
footer .font_16 {
    line-height: 24px;
}
footer .font_10 {
    line-height: 15px !important;
}
.font_10 {
    font-size: 10px !important;
    line-height: 12px !important;
}
.footer_menu.hide_get_touch li:nth-child(3) {
    display: none;
}
.mt_23 {
    margin-top: 23px;
}
.order_pro_det h3 p {
    margin: 0;
}
/******** new style Header and Footer css - Ends ***********/