a{outline: none;}

.montserrat{
    font-family: 'Montserrat', sans-serif;
}
.roboto{
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
.roboto_condensed{
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
}
body{
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
ul{
    list-style: none;
    padding: 0px;
    margin: 0px;
}
.logo-wrapper {
    padding: 20px 0;
}
.site-header{
    margin: 0 0 30px 0;
}
.customerTopHeader{
    padding-top: 52px;
}
.header-top {
    padding: 0;
    text-align: right;
}
.header-top .textwidget {
    color: #141414;
    font-size: 12px;
    letter-spacing: 0.02em;
    opacity: 0.7;
    font-weight: 400;
}
.site-logo a img{
    margin: 0px;
    float: left;
}
/* .site-logo:hover img {
    opacity: 0.5;
} */
.top-cart{
    margin: 30px 0 0 0;
    position: relative;
    display: inline-block;
}
.top-cart .icon {
    background: rgba(0, 0, 0, 0) url(../images/icons_set.png) no-repeat scroll 0 -90px;
    display: inline-block;
    height: 20px;
    margin-right: 8px;
    width: 13px;
    position: relative;
}
.wrapper-top-cart .first {
    color: #323232;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
}
.top-cart a{
    text-decoration: none;
}





.widget_shopping_cart_content{
    display: none;    
}
.header-logo-cart:hover .widget_shopping_cart_content,
.top-cart:hover .widget_shopping_cart_content{
    display: block;
    position: absolute;
    width: 340px;
    background-color: #fff;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    top: 25px;
    right: 0px;
    z-index: 9;
}
.menu_cart_product{
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 10px 0 !important;
    margin: 0 0 10px 0 !important;
}
.menu_cart_product li {
    text-align: left;
    padding: 4px 0 !important;
    width: 100%;
}
.menu_cart_product li a, 
.menu_cart_product li span{
    display: inline-block;
    vertical-align: top;
} 
.menu_cart_product_img{
    width: 78px;
    height: 78px;
    border: 1px solid #e1e1e1;
    margin: 0 15px 0 0;   
}
.menu_cart_product_name {
    width: 174px;
}
.remove_from_cart_button {
    text-align: center;
    color: #767676;
}
.remove_from_cart_button:hover {
    color: #ff0000;
}
.menu_cart_product li a img{
    width: 100%;
}
.menu_cart_product span{
    color: #191919;
    display: block;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 16px;
}
.menu_cart_price_total{
    margin: 15px 0;
}
.menu_cart_price_total span{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.05em;
}
.menu_cart_price_total strong{
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.05em;
}
.mini_cart_buttons a{
    font-weight: 600 !important;
}
.mini_cart_buttons a.checkout.wc-forward {
    margin: 15px 0 0 0;
    background: none;
    color: #000 !important;
    text-decoration: underline !important;
}













.top-cart .cart-contents {
    background-color: #ffe5e5;
    border-radius: 100%;
    margin-left: 6px;
    padding: 4px 8px;
    color: #323232;
    font-size: 12px;
    line-height: 20px;
}
/** site footer **/

.site-footer{
    margin-top: 12px;
    background-color: #141414;
    font-weight: lighter;
    padding: 37px 0 73px;
    letter-spacing: 0.02em;
}
.footer-top {
    padding-bottom: 35px;
}
.site-footer h3 {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin: auto auto 20px auto;
}
.footer_contact {
    color: #ffffff;
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 24px 0;
    line-height: 22px;
}
.site-footer .footer-bottom {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border-top: 1px solid #282828;
    padding: 25px 0 0 0;
}
.copyright {
    color: #969696;
    font-size: 12px;
    margin-bottom: 6px;
    letter-spacing: 0.02em;
    font-weight: 400;
}
.site-footer .widget ul {
    list-style: none;
    margin: 0;
    padding: 0px;
}
.site-footer .widget ul li {
    list-style: none;
    position: relative;
    border: 0;
    margin-bottom: 3px;
}
.site-footer .widget ul li a {
    font-size: 12px;
    line-height: 12px;
    color: #969696;
    font-weight: 300;
}
.site-footer .widget ul li:hover a{
    color: #FFF;
}
.footer-top ul.social {
    margin: 4px 15px 20px 0;
    display: inline-block;
    vertical-align: middle;
}
.footer-top ul.social li a {
    background: rgba(0, 0, 0, 0) url(../images/social.png) no-repeat scroll 0 0;
    display: inline-block;
    height: 36px;
    text-indent: -999em;
    width: 36px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
}
.footer-top ul.social li a:hover {
    background-position: 0 -44px;
    text-decoration: underline;
    color: #fff;
}
.footer-top .text {
    display: inline-block;
    vertical-align: middle;
}
.site-footer .widget {
    margin: 10px 0;
    border: 0;
    padding: 0;
}

/** site menu **/
.site_menu{
    background-color: #141414;
    height: 48px;
    position: relative;
}
.site_menu.fixed-header {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9;
    left: 0;
    right: 0;
    bottom: 0;
}
.header-logo-cart,
.header-logo-fix{
    display: none;
}
.site_menu.fixed-header .header-logo-fix {
    display: inline-block;
    position: absolute;
    left: 5%;
    top: 0;
    bottom: 0;
}
.site_menu.fixed-header .header-logo-cart {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    cursor: pointer
}
.site_menu.fixed-header .header-logo-cart .icon {
    background: rgba(0, 0, 0, 0) url(../images/icons_new14.png) no-repeat scroll -24px -110px;
    border-left: 1px solid #4d4d4d;
    display: inline-block;
    height: 46px;
    text-indent: -999em;
    width: 65px;
}
.site_menu ul{
    margin: 0px;
    padding: 0px;
}
.site_menu ul li{
    display: inline-block;
    vertical-align: top;
    margin: 0px;
    padding: 0px;
}
.site_menu ul li a.menu_item {
    background-clip: padding-box;
    border-radius: 0;
    color: #ffffff;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    height: auto;
    letter-spacing: 0.05em;
    line-height: 15px;
    margin: 18px 19.5px 13px;
    padding: 0;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out 0s;
    border-bottom: 2px solid transparent;
}
.site_menu ul li:first-child a {
    margin-left: 0px;
}

.site_menu ul li a.active,
.site_menu ul li:hover a,
.site_menu ul li a.active {
    border-bottom: 2px solid #FFF;
}

.page-title {
    color: #000000;
    text-align: left;
    font-size: 20px;
    margin-bottom: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700;
}
.page-title span{
    font-size: 12px;
    font-weight: 600;
}

/** user login **/
.user_login{
    width: 50%;
    margin: 0 auto;
}
.action_form{
    border: 1px solid #e1e1e1;
    padding: 20px;
    margin:0;
    text-align: left;
}

.user_login h2{
    font-weight: 600;
    color: #000;
    font-size: 26px;
    line-height: 1.25;
    margin: 0 0 24px 0;
    text-rendering: optimizeLegibility;
}

.add_new_card_form p,
.add_new_card_form label,
.my_paypal_form p,
.action_form label {
    line-height: 2;
    display: block;
    color: #2d2d2d;
    font-size: 12px;
    margin: 0;
}
.required {
    color: red;
}

input.cus_field {
    height: 42px;
    line-height: 42px;
    width: 100%;
    border: 0px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #f6f6f6;
}
select.cus_field{
    height: 42px;
    line-height: 42px;
    width: 100%;
    border: 0px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #f6f6f6;
}
textarea.cus_field{
    line-height: 25px;
    min-height: 150px;
    width: 100%;
    border: 0px;
    padding: 0 10px;
    font-size: 12px;
    background-color: #f6f6f6;
}
.action_form .form-group{
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
}
.cus_button {
    background-color: #000000;
    height: 38px;
    line-height: 41px;
    padding: 0 20px!important;
    font-weight: normal;
    border-radius: 0;
    -webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    text-transform: uppercase;
    color: #ffffff;
    border: 0px;
    font-size: 13px;
    cursor: pointer;
}
.cus_button:hover {
    background-color: #e95144;
}
button.cus_button{
    /* letter-spacing: 0.15em; */
    font-size: 12px;
}
.login_remember{
    display: inline-block !important;
    line-height: 2;
    cursor: pointer;
    margin: 0 5px 0 10px !important;
    position: relative;
}
.login_remember input[type="checkbox"] {
    position: absolute;
    z-index: -1000;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.login_remember span::before {
    display: inline-block;
    position: absolute;
    left: 0;
    top: 4px;
    bottom: 0;
    width: 16px;
    height: 16px;
    background-color: #ededed;
    content: '';
    padding: 1px 0 0 1px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.login_remember span {
    padding: 0 0 0 22px;
    height: 16px;
    line-height: 16px;
}

.new_distributor_sign_up{
    padding: 0 0 0 22px;
    height: 16px;
    line-height: 16px;
    float: right;
    margin-top: 10px;
    cursor: pointer;
}
.user_registration a,
.new_distributor_sign_up a{
    color: #000000;
}

.lost_password {
    margin-bottom: 10px;
}
.lost_password a{
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
}
.return_to_main_site{
    float: right;
    margin-top: 10px;
    margin-bottom: 25px
}
.return_to_main_site a{
    text-decoration: underline;
    font-size: 13px;
    color: #000000;
}
/** Back to top **/
.scrollup {
    position: fixed;
    right: 40px;
    bottom: 75px;
    z-index: 99;
    background: #000;
    padding: 8px 15px;
    border: 1px solid #FFF;
}
.scrollup i{
    font-size: 30px;
    font-weight: 600;
    color: #FFF;
}
.alert-wrapper {
    padding: 15px;
    margin: 0 0 30px 0;
    background-color: #fff;
    color: inherit;
    border-style: solid;
    border-width: 1px;
    box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 3px #e1e1e1;
    border-color: #e1e1e1;
}
.alert-error{
    position: relative;
}

.alert-wrapper span{
    font-size: 12px;
    margin: 0 0 0 10px;
}
.alert-wrapper a {
    display: inline-block;
    float: right;
    position: relative;
    margin: -7px -7px 0 0;
}

.alert-error li{
    font-size: 15px;
    color: #000000;
    font-weight: 400;
}
.alert-wrapper i{
    font-size:10px; 
}
.alert-wrapper ul li i {
    font-size: 18px;
    padding: 0 8px 0 0;
}
.alert-wrapper.alert-error ul li i{
    color: #b81c23;
}
.alert-wrapper.alert-success ul li i{
    color: #155724;
}
.alert-wrapper.alert-danger ul li i{
    color: #721c24;
}
.alert-wrapper.alert-warning ul li i{
    color: #856404;
}
.alert-success{
    background:none !important;
    border: 0px !important;
}
.lost_reset_password p {
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px !important;
}
.user_name_email_field label{
    display: block;
    width: 100%;
}
.user_name_email_field input{
    height: 42px;
    line-height: 42px;
    background-color: #f6f6f6;
    border: 1px solid transparent;
    -webkit-transition: border-color .3s ease-out;
    transition: border-color .3s ease-out;
    width: 47%;
}

/** user registration **/
.user_registration .register{
    border: 1px solid #d3ced2;
    padding: 20px;
    margin: 2em 0;
}
.user_registration{
    width: 75%;
}
.name_field{
    width: 47%;
}
.input_type_file input[type=file]{
    width: 35%;
}
.user_registration .form-group input[type=checkbox]{
    margin: 6px 0 0 0;
    vertical-align: top;
}
.input_type_file a{
    text-decoration: underline;
}

/** terms and condition **/
.terms_and_condition h3 {
    color: #4e4e4e;
    font-size: 20px;
    line-height: 1.2;
    clear: both;
    font-weight: 700;
    margin: 0 0 10px 0;
}
.terms_and_condition p {
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
}
.terms_and_condition p a{
    color: #2d2d2d;
}

/** product-list-container **/
.product-list-container{
    margin: auto auto 50px auto;
    width: 900px;
}
.product-search-container{
    background: #333;
    margin: 0px;
    padding: 20px;
}
.product-search-form{
    width: 35%;
    float: left;
}
.product-search_box{
    background: #f3f3f3 none repeat scroll 0 0;
    border: 1px solid #ffffff;
    border-radius: 5px;
    height: 33px;
    margin: 0;
    font-weight: 300;
    font-size: 11px;
    padding: 0 10px;
    width: 100%;
}
.product-paging{
    float: right;
    margin-left: 3%;
}
.product-btn-group button{
    float: left;
    width: 40px;
    min-width: 40px;
    background: none repeat scroll 0 0 #ddd;
    border: 1px solid #fff;
    border-radius: 5px;
    box-shadow: none;
    color: #333;
    font-size: 13px;
    height: 35px;
    line-height: 31px;
    margin: 0 1px;
    outline: 0 none;
    padding: 0;
    font-weight: bold;
}
#prev:disabled {
    color: #c1c0c0 !important;
    cursor: none;
}
.products_list table {
    background-color: #eaebec;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 2px #d1d1d1;
    color: #666;
    font-size: 12px;
    margin: 0;
    text-shadow: 1px 1px 0 #fff;
    vertical-align: middle;
    width: 100%;
    table-layout: inherit;
}
.products_list table th {
    padding: 21px 3px 22px;
    padding-left: 3px;
    text-align: center;
    background: #ededed;
    background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#ebebeb));
    background: -moz-linear-gradient(top, #ededed, #ebebeb);

}
.products_list table th:first-child {
    text-align: left !important;
    padding-left: 20px !important;
}
.hidden {
    display: none !important;
}
.products_list table tr {
    padding-left: 20px;
    text-align: center;
    display: table-row;
}
.products_list table tr td {
    padding: 12px 10px;
    text-align: left;
    vertical-align: middle !important;
    background: #fafafa;
    background: -webkit-gradient(linear, left top, left bottom, from(#fbfbfb), to(#fafafa));
    background: -moz-linear-gradient(top, #fbfbfb, #fafafa);
    font-weight: 500;
    font-size: 11px;
    color: #666;
    border: 1px solid #e1e1e1;
}

.products_list  table tr:nth-child(2n+1) td {
    background: none repeat scroll 0 0 #f6f6f6;
}
.product_list_img{
    width: 60px;
    display: inline-block;
    margin-right: 15px;
}
.product_list_img img{
    width: 60px;
    height: 60px;
}
.product_list_btn{
    border: medium none;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0 10px;
    text-transform: none;
    width: 100%;
    border-radius: 2px;
    cursor: pointer;
    background-color: #000000;
    border-color: #000000;
    font-weight: 300;
    letter-spacing: 0.05em;
}
.product_list_btn:hover{
    background-color: #e95144;
    color: #fff;
}
.products_list table a:link {
    color: #666;
    text-decoration: none;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    display: block;
}
.product-search-container-bottom{
    padding: 6px;
}
.cart_add_product_img{
    width: 195px;
    height: 195px;
}
.variant-changer {
    padding: 5px 10px;
    line-height: 1.35;
    height: 36px;
    border: 1px solid #cbcbcb;
    width: 100%;
    background-color: #FFF;
}
.cart_add_product_btn{
    border: medium none;
    box-shadow: none;
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 36px;
    line-height: 36px;
    margin: 0;
    padding: 0 10px;
    text-transform: none;
    width: 100%;
    border-radius: 2px;
    cursor: pointer;
    background-color: #000000;
    border-color: #000000;
    font-weight: 300;
    letter-spacing: 0.05em;
}
.cart_add_product_btn:hover{
    background-color: #28a745;
    border-color: #28a745;
}
span.compared_price {
    display: block;
    color: #f00;
    text-decoration: line-through;
}
table.cart_showpopUp tr td {
    background: none repeat scroll 0 0 #fdfdfd !important
}
.shopping-cart-wrapper{
    height: 50px;
    width: auto;
    bottom: 150px;
    right: 5px;
    position: fixed;
}
.shopping-cart {
    background-image: url('../images/customer_images/header_cart_icon.svg');
    background-color: var(--pri_color);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: 10px center;
    border-radius: 8px 0 0 8px;
    padding: 8px 10px 6px 45px;
    color: #fff;
    line-height: 35px;
    z-index: 1000;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.05em;
}
.shopping-cart a{
    color: #FFF;
}
/** //EnD product-list-container **/

/** cart page **/
.steps-wrapper {
    text-align: center;
    font-size: 26px;
    margin: 0 0 50px 0;
}
.steps-wrapper span {
    color: #e1e1e1;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 10px 15px 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
}
.cart_active{
    color: #4e4e4e !important; 
}
.steps-wrapper span i {
    width: 28px;
    height: 28px;
    line-height: 30px;
    border-style: solid;
    border-width: 1px;
    display: inline-block;
    margin: 0 10px 0 0;
    border-color: #e1e1e1;
}
.cart_table{
    width: 100%;
    border: 1px solid;
    border-color:#e1e1e1;
    border-width: 1px 0 0 0;
    margin: 0 0 22px;
}
.cart_table tr th {
    padding: 20px 45px;
    font-size: 16px;
    vertical-align: middle;
    font-weight: 700;
    border-left: 1px solid #e1e1e1;
}
.cart_table tr th:first-child {
    border-left: 0px solid #e1e1e1;
}
.cart_product_details{
    margin: 0px 20px 0px 20px;
}
.cart_product_details a{
    font-size: 16px;
    color: #000;
    text-decoration: none !important;
}
a.remove {
    display: block;
    font-size: 20px !important;
    height: 25px;
    width: 25px;
    text-align: center;
    line-height: 1;
    border-radius: 100%;
    color: red!important;
    text-decoration: none;
    font-weight: 700;
    border: 0;
    padding: 2px 0 0 0;
    cursor: pointer !important;
}
a.remove:hover {
    color: #fff!important;
    background: red;
}
dl.variation dt {
    float: left;
    clear: both;
    margin-right: .25em;
    display: inline-block;
    list-style: none outside;
    color: #111111;
    font-size: 11px;
    font-weight: 400;
}
.variation-Every1Months{
    color: #111111;
    font-size: 11px;
    font-weight: 400;
}
.cart_table .cart_product_img{
    width: 80px;
    height: 80px;
    border: 1px solid #e1e1e1;
}
.cart_table tr td {
    border: 1px solid #e1e1e1;
    border-right: 0px;
    padding: 20px 45px;
}
.border-left-none{
    border-left: 0px !important;
}
.cart_product_remove{
    border-left: 0px !important;
}
.cart_table tr td.product-thumbnail {
    border-right: 0;
    padding-right: 0;
    width: 125px;
}
.cart_product_name a{
    color: #000000;
}
.cart-product-subtotal,
.cart_product_price{
    font-size: 18px;
    font-weight: bold;
    color: #000;
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
}
.cart_product_number input{
    height: 38px;
    padding: 10px;
    width: 60px;
    border: 1px solid #e1e1e1;
}
.cart_table .actions{
    padding: 20px 0px
}
.cart_table .actions .input-text{
    width: auto;
    float: left;
    padding: 0 10px;
    margin: 0 10px 0 0;
    height: 38px;
    line-height: 38px;
    border: 1px solid #f6f6f6;
    background-color: #f6f6f6;
    font-size: 14px;
}
.link-to-shop {
    background-color: #e95144;
    border: 1px solid #e95144;
    color: #fff;
    height: 38px;
    margin: 0 0 0 10px;
    line-height: 38px;
    padding: 0 20px;
    font-weight: normal;
    border-radius: 0;
    display: inline-block;
    width: auto;
    -webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none !important;
}

.update_cart{
    background-color: #000;
    border: 1px solid #000;
    opacity: 0.5;
}
.update_cart:hover {
    background-color: #e95144 !important;
    border: 1px solid #e95144 !important;
}
.link-to-shop:hover{
    background-color: #3e3e3e;
    border: 1px solid #3e3e3e;
    color: #fff;
}
.inner_title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom:1px solid #e1e1e1;
    padding: 0 0 10px 0;
    margin: 0 0 30px 0;
}
.cart_totals{
    padding: 15px 30px 30px 30px;
    border: 1px solid #e1e1e1;
}
.shop_table {
    width: 100%;
}
.shop_table tr td{
    padding: 10px 0px 10px 0;
    font-weight: normal;
    border-bottom: 1px solid #e1e1e1;
    vertical-align: top;
    font-size: 16px;
}
.shop_table tr td:first-child{
    text-transform: uppercase;
}
.shop_table tr td:last-child{
    text-align: right;
}
.shipping-methods .amount {
    font-weight: 700;
}
.shipping-methods li input {
    position: absolute;
    z-index: -1000;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}
.shipping-methods li input:checked + label::after {
    background-color: #3e3e3e;
}
.shipping-methods input + label::after {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin: 0 0 1px 8px;
    background-color: #ededed;
    border: 4px solid #ededed;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    content: '';
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.shipping-methods li input + label {
    cursor: pointer;
}
.shipping-methods li label {
    display: inline;
}
.shipping-methods li {
    padding: 5px 0;
    margin: 0 0 .5em;
    line-height: 1.5em;
    list-style: none outside;
}
.woocommerce-shipping-destination{
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
}
.wc-forward{
    height: 38px;
    display: block;
    line-height: 38px;
    padding: 0 20px;
    background-color: #000000;
    border-radius: 0;
    -webkit-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    transition: background-color 0.3s ease-out, border-color 0.3s ease-out, color 0.3s ease-out;
    text-transform: uppercase;
    position: relative;
    font-size: 14px;
    text-align: center;
    color: #FFF !important;
    text-decoration: none !important;
    cursor: pointer;
}
.wc-forward:hover{
    background-color: #e95144;
}
.first-payment-date small {
    color: #777;
    font-weight: normal;
    font-size: 14px;
    margin: 1px 0 0 0;
    display: block;
}
.recurring_total {
    padding: 10px 0 10px 25px !important;
    font-size: 24px !important;
    font-weight: 900 !important;
}
.recurring_total_text {
    padding-top: 20px !important;
    font-weight: bold !important;
}
.wc-proceed-to-checkout{
    margin-top: 35px;
}
.order-total.recurring-total td{
    border-bottom: 0px !important;
}
.subtotal{
    color: #777;
    font-size: 16px;
}
.subtotal span {
    color: #000;
    font-size: 15px;
    font-weight: 600;
}
.recurring_totals_title {
    text-align: left !important;
}
.sub-order-total td {
    border: 0px !important;
    padding: 20px 0 !important;
    font-weight: bold !important;
    font-size: 18px !important;
}
.Price-currencySymbol{
    padding: 0 6px 0 0;
}
.sub-order-total-usd span{
    font-size: 24px !important;
}
.woocommerce-Price-amount.amount{
    font-size: 15px;
}
.total_per_month{
    margin: 15px 0 0 0;
}
.order-total td{
    border: 0px !important;
}
/** //EnD cart page **/


/** checkout css **/
.col2-set {
    width: 48.5%;
}

.checkout_title{
    color: #4e4e4e;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 10px 0;
    margin: 0 0 30px 0;
}
.checkout_address_form form{
    padding: 0px;
    border: 0px;
}
.agree_wrapper span{
    display: inline-block;
    vertical-align: top;
    margin: 10px 10px 0 0;
}
.agree_wrapper span input {
    margin: 4px 2px 0 0;
    display: inline-block;
    vertical-align: top;
}
.ship-to-different-address{
    margin: 35px 0;
}
.ship-to-different-address span{
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 0 10px 0px;
    margin: 0 0 30px 0;
    position: relative;
}
.checkout_table span.img {
    display: none;
}
.product-quantity {
    display: block;
    font-size: 12px;
    color: #111;
    text-transform: capitalize;
}
.checkout_product_name{
    display: block;
    font-size: 12px;
    color: #111;
}
.checkout_table .variation{
    margin-top: 5px;
}
.checkout_table .variation .variation-Every1Months{
    font-size: 13px;
}
.shipping_address_enter{
    font-size: 14px;
    color: #767676;
    font-weight: 400;
}
.shipping_cost_subtotal {
    font-size: 13px !important;
}
.per_month{
    color: #767676;
}
.review-order-table-wrapper{
    margin: 0 0 60px 0;
}
.woocommerce-checkout-payment{
    background: transparent;
    border: 1px solid #e1e1e1;
    padding: 30px;
    margin: 0 0 60px 0;
}
.payment_box{
    padding: 25px;
    margin: 14px 0;
    border-radius: 0;
    background-color: #f6f6f6;
    color: inherit;
    font-size: 14px;
    color: #767676;
}
.fieldset{
    border: 1px solid #e1e1e1;
    margin: 0px 0px 24px;
    padding: 5px 20px;
}
.fieldset input[type=text]{
    border: 1px solid rgb(225, 225, 225);
}

.fieldset .name_field{
    width: 49% !important;
}
.card_exp_date select{
    width: 49%;
    border: 1px solid rgb(105, 191, 41);
}
.woocommerce-checkout-payment .wc-forward{
    border: 0px !important;
    width: 100%;
    cursor: pointer;
}
.iCanPayTermsAndCondition{
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px;
}
.iCanPayTermsAndCondition a{
    color: #2d2d2d;
}
.iCanPayTermsAndCondition span{
    display: block;
    font-size: 11px;
}
/** //EnD checkout css **/

/** ORDER RECEIVED **/
.woocommerce-notice.woocommerce-notice--success.woocommerce-thankyou-order-received {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 0 2px #ffffff inset, 0 0 0 3px #e1e1e1 inset;
    color: inherit;
    padding: 15px 15px 15px 45px;
    border-color: #e1e1e1;
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px !important;
}
.order_details_list {
    background: #f1f1f1 none repeat scroll 0 0;
    margin: 1em 0 3em 0;
    padding: 15px;
}
.order_details_list li {
    width: 18%;
    display: inline-block;
    vertical-align: top;
    margin-right: 2em;
    text-transform: uppercase;
    font-size: .715em;
    line-height: 1;
    border-right: 1px dashed #d3ced2;
    padding-right: 2em;
    margin-left: 0;
    padding-left: 0;
    list-style-type: none;
}
.order_details_list li strong {
    display: block;
    font-size: 1.4em;
    text-transform: none;
    line-height: 1.5;
}

.billing_address_container .checkout_title,
.woocommerce-order-details .checkout_title{
    color: #000 ;
}
.receive_order_details tr th{
    text-align: center;
    background: #f6f6f6;
    text-align: center;
    border: 1px solid  #e1e1e1;
}
.my_account_orders tr td,
.my_account_orders tr th,
.receive_order_details tr td{
    border: 1px solid  #e1e1e1;
    padding: 6px 14px;
    text-align: left;
    font-size: 14px;
}
.my_account_orders tr td a,
.receive_order_details tr td a {
    color: #000;
    font-size: 14px;
    text-decoration: none !important;
}
.my_account_orders tr td a:hover,
.receive_order_details tr td a:hover {
    color: inherit;
}
.order_receive_product_name{
    display: block;
}
.receive_order_details tr td a:hover{
    color: #76767a;
}
.receive_order_details tr td img{
    width: 50px;
    height: 50px;
}
.receive_order_details tr td:last-child{
    text-align: right;
}
.receive_order_details tr td strong{
    display: inline-block;
}
.shipped_via,
.note{
    color: #767676;   
}
.shipping-address{
    font-style: normal;
    margin-bottom: 0;
    border: 1px solid rgba(0,0,0,.1);
    border-bottom-width: 2px;
    border-right-width: 2px;
    text-align: left;
    width: 100%;
    border-radius: 5px;
    padding: 6px 12px;
    color: #767676;  
    font-size: 13px;
}
.shipping-address p i{
    margin: 0 8px 0 0;
}
.my_account_orders{
    width: 100%;
    margin-bottom: 15px;
}
.my_account_orders tr td:first-child,
.my_account_orders tr th:first-child{
    border-left: 0px;
}
.my_account_orders tr td:last-child,
.my_account_orders tr th:last-child{
    border-right: 0px;
}
.billing_address_container{
    width: 48%;
}
.shipping-address p {
    margin: 3px 0;
    color: #000;
    font-size: 13px !important;
}
.order_receiver_wrapper{
    margin-bottom: 50px;
}
/** //EnD ORDER RECEIVED **/
.footer_contact span {
    font-weight: 100;
    font-size: 15px;
    display: block;
    margin: 0 0 5px 0;
}


/** left_menu **/
.left_menu{
    border: 1px solid #e1e1e1;
    padding: 8px 25px;
}
.left_menu ul li{
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 5px 0;
    margin: 5px 0;
}
.left_menu ul li:last-child{
    border-bottom: 0px;
}
.left_menu ul li a{
    font-size: 14px;
    color: #000;
    font-weight: 400;
    text-decoration: none;
    display: block;

}
.left_menu ul li a.active{
    color: #f02121;    
}
.left_menu ul li a i {
    display: inline-block;
    margin: 0 5px 0 0;
}
.commission_aside,
.order_aside{
    width: 45%;
    margin: 0px auto 25px auto;
}
.commission_aside h3, .order_aside h3 {
    font-size: 14px;
    margin: 0px;
    line-height: 17px;
    font-weight: 700;
    padding: 0 0 8px 0;
}
.commission_aside ul li, .order_aside li {
    color: #767676;
    border-bottom: 1px solid #e1e1e1;
    padding: 0 0 5px 0;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 400;
}
.commission_aside ul li a, .order_aside li a, .affiliate_url a{
    color: #000;

}
.affiliate_url{
    font-size: 15px;
    color: #767676;
}

.table_search{
    text-align: right;
    margin-bottom: 10px;
}
.table_search select, .table_search input {
    display: inline-block;
    width: 20%;
    margin: 0 0 0 6px;
}
.table_search select, .table_search input {
    display: inline-block;
    width: 28%;
    margin: 0 0 0 6px;
}
.table_search input[type=submit] {
    width: 95px;
}
.table_search label{
    color: #767676;
    font-size: 14px;
}
.cus_pagination{
    display: inline-block;
}
.cus_pagination li {
    display: inline-block;
    margin: 0 -2px;
}
.my_account_orders tr td{
    vertical-align: middle;
    color: #767676;
}
.my_account_orders tr td span{
    color: #000;
}
.my_account_orders tr td .order-rsp-change{
    float: right;
    font-size: 20px;
    color: #767676;
}
.order_short_summery{
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px !important;
}
.order_short_summery mark{
    color: #FFF;
    background-color: #000;
}
.my_account_orders_view tr td img{
    width: 50px;
    height: 50px;
}
.my_account_orders_view{
    border-bottom: 1px solid #e1e1e1;
}
.my_account_orders_view tr td:first-child, .my_account_orders_view tr th {
    background-color: #f6f6f6;
    color: #212529;
    font-weight: 400;
    font-size: 15px;
    font-family: "Roboto Condensed", Helvetica, Arial, sans-serif;
}
.my_account_orders_view tr td:last-child{
    text-align: right;
}
.my_account_orders_view span{
    font-weight: 400;
    color: #000;
    font-size: 14px;
}
.product_name {
    background-color: #FFF !important;
}
.product_name strong,
.product_name span{
    display: inline-block;
    color: #767676 !important;
}
.related_subscription{
    margin-bottom: 40px;
}
.page-title a {
    text-decoration: underline;
    font-size: 11px;
    color: #000;
    display: inline-block;
    margin: 6px 0 0 0;
    vertical-align: middle;
}
.form-group small em{
    font-size: 12px;
}
.edit_account_form {
    border: 0px;
    padding: 0px;
}
.edit_account_form .form-group h3{
    font-size: 15px;
    margin: 25px 0 0 0;
}
.my_paypal_form {
    margin: 0 0 30px 0;
    border: 0px;
    padding: 0;
}
.my_paypal_form .cus_field {
    width: 40%;
}
.order-actions a {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px;
}
.order_actions_edit{
    font-size: 20px !important;
    color: #767676 !important;
}

.add_new_form{
    background-color: #f6f6f6;
    margin: 0 0 40px 0;
}
.add_new_form input.cus_field{
    background-color: #FFF; 
    border: 1px solid #e1e1e1;
}
.section_blog{
    margin: 0 0 15px 0;
}
.section_blog span{
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    font-size: 12px;
}
.product_variable_table tr td input[type=text],
.section_blog input[type=text] {
    margin: 0 5px;
    width: 60px;
    height: 30px;
    border: 1px solid #e1e1e1;
    padding: 5px;
}
.product_variable_table tr td input[type=radio],
.section_blog input[type=radio] {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
}
.section_blog label{
    font-size: 15px;
    display: inline-block;
}
.panel{
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}
.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
}
.panel-body {
    padding: 15px;
}
.product_variable_table tr td,
.product_variable_table tr th{
    color: #767676;
    font-size: 13px;
}
.total_summery p {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px !important;
    text-align: right;
}
.payment_received tr td span{
    display: block;
    color: #767676;
}
.customer-MyAccount-content{
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px ;
}
.customer-MyAccount-content a{
    color: #000;
}
.customer_subscriotion_view tr td {
    color: #000 !important;
    text-align: left !important;
}

.customer_address address p{
    color: #767676 !important;
}
.edit {
    font-size: 28px;
    display: inline-block;
    vertical-align: top;
    color: #767676;
}
.add_new_card{
    margin: 0 0 20px 0;
}
.add_new_card span{
    display: inline-block;
}
.add_new_card_form .page-title{
    margin-bottom: 10px;
}
.line {
    width: 48px;
    height: 2px;
    background: #000;
    margin-bottom: 20px;
}
.get_in_touch .action_form{
    padding: 0px;
    border: 0px;
}
.get_in_touch .form-group input[type=text]{
    width: 80%;
}
.get_in_touch_address p{
    font-size: 12px;
}
.inline_checkbox{
    display: inline-block;
    margin: 0 15px;
}
.inner_page_title{
    font-size: 18px;
    margin-bottom: 12px;
}
.athletes_list_wrapper,
.my_account{
    margin-bottom: 30px;
}

.athletes_list {
    border: 1px solid #e1e1e1;
    border-radius: 3px;
    padding: 8px;
    margin: 0 0 30px 0;
}
.athletes_list img {
    width: 252px;
    height: 252px;
}
.athletes_list h3 {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
}
.cus_breadcrumb{
    background: none;
    padding: 0px;
}
.cus_breadcrumb li{
    font-size: 12px;
}
.cus_breadcrumb li a{
    color: #000;    
}
.view-controls-wrapper {
    background: #eeeeee;
    border: 1px solid #cbcbcb;
    color: #3c3c3c;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 40px;
    text-transform: uppercase;
}
.view-controls-wrapper p{
    border-right: 1px solid #cbcbcb;
    margin: 0px;
    padding: 21px 20px;
}
.list-grid-switcher {
    float: left;
    margin: 17px 0 0 20px;
}
.list-grid-switcher span{
    display: inline-block;
    margin: 0 8px;
    cursor: pointer;
    font-size: 24px;
    height: 28px;
    line-height: 28px;
    color: #b4b4b4;
}
.list-grid-switcher span:hover,
.list-grid-switcher span.active{
    color: #000;
}

.view-controls-wrapper .orderby {
    border: 1px solid #cbcbcb;
    font-size: 14px;
    padding: 10px;
    background-color: #fff;
    width: 80%;
    float: right;
    text-transform: uppercase;
    color: #505050;
    margin: 8px 15px 0 0;
}
.view-controls-wrapper .shop-label,
.view-controls-wrapper .product-pager{
    display: inline-block;
    vertical-align: middle;
}
.view-controls-wrapper .shop-label {
    margin: 11px 6px 0 0px;
}
.view-controls-wrapper .product-pager select{
    border: 1px solid #cbcbcb;
    font-size: 14px;
    padding: 10px;
    background-color: #fff;
    width: 80%;
    float: right;
    text-transform: uppercase;
    color: #505050;
    margin: 8px 15px 0 0;
}
.orderby_search{
    width: 176px;
}
.refer_produc_wrapper{
    position: relative;
    margin: 0 0 30px 0;
}
.refer-img-wrapper{
    margin: 0 0 15px 0;
    position: relative;
}
.onsale {
    background: #ffffff none repeat scroll 0 0;
    border: 2px solid #e95144;
    color: #e95144;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    padding: 0 5px;
    text-transform: uppercase;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 99;
}
.refer_product_short_desc{
    text-align: center;
}
.refer_product_short_desc .product_title{
    color: #2d2d2d;
    display: block;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 20px;   
    font-weight: 400;
    letter-spacing: 0.05em;
}
.product_price{
    color: #000;
    font-weight: 600;
    font-size: 17px;
}
.product_quick_view{
    background:rgba(255,255,255,0.5)  ;
    width: 100%;
    height: 100%;
    display: none;
}
.refer_produc_wrapper:hover .product_quick_view{
    display: block;
    position: absolute;
    top: 0px;
    text-align: center;
}
.add_to_wishlist{
    color: #000;
    background: #ffe8e8;
    display: inline-block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 34px;
    text-align: center;
    font-size: 14px;
}
.add_to_wishlist:hover{
    color: #FFF;
}

.refer_product_select_option {
    display: inline-block;
    padding: 2px 15px;
    background: rgba(0, 0, 0, 0);
    border: 2px solid #000000;
    color: #000000 !important;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;  
    font-size: 15px;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    text-decoration: none !important;
}
.refer_product_select_option:hover {
    background-color: #000;
    color: #FFF !important;
    -webkit-transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    text-decoration: none !important;
}
.refer_product_quickview {
    display: block;
    width: 100%;
    padding: 2px;
    background: #000;
    color: #FFF !important;
    font-weight: 300;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    text-decoration: none !important;
}
.add_to_wishlist_wrapper{
    margin: 35% 0 0 0;
}
.refer_product_select_option_wrapper{
    margin: 5% 0 0 0;
}
.refer_product_quickview_wrapper{
    position: absolute;
    width: 100%;
    bottom: 0;
}
.refer_product_short_desc .refer_product_select_option_wrapper{
    display: none;
}
.list_view {
    width: 100%;
    max-width: 100%;
    flex: auto;
    border-bottom: 1px solid rgb(223, 228, 230);
    margin: 0 0 30px 0px;
    padding: 15px;
}
.list_view .refer-img-wrapper{
    width: 200px;
    max-width: 250px;
    float: left;
    margin: 0 40px 0 0;
}
.list_view .product_quick_view{
    display: none !important;
}
.list_view .onsale {
    left: 0;
    right: inherit;
}
.list_view .refer-img-wrapper:hover img{
    opacity: 0.5;
}
.list_view .refer_product_short_desc{
    text-align: left;
}
.list_view .refer_product_short_desc .refer_product_select_option_wrapper{
    display: block;
    margin: 0px;
}
.list_view .refer_product_short_desc .refer_product_select_option_wrapper a{
    position: inherit;
    margin-top: 15px;
}
.product_title{
    color: #000000;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 8px;
}
.sample-vendor {
    color: #848484;
    font-size: 12px;
    font-weight: 300;
    margin: 0 0 8px 0;
}
.product-infor ul{
    margin: 0 0 20px 0;
}
.product-infor ul li{
    margin: 0 0 8px 0;
    font-size: 11px;
    font-weight: 700;
}
.product-infor ul li label{
    color: #000000;   
    padding-right: 5px;
    width: 100px;
    text-transform: uppercase;
    margin: 0px;
}
.product-infor ul li span {
    font-size: 11px;
    color: #2d2d2d;
    font-weight: 400;
}
.short-description p{
    font-size: 24px;
    line-height: 1.2;
    color: #4e4e4e;
    font-weight: 700;
    margin: 0 0 24px 0;
}
.product_tvc_wrapper{
    width: 49%;
}
.product_tvc_wrapper h5{
    font-size: 14px;
}
.product_tvc{
    display:inline-block;
    height:180px;
    position:relative;
    width:320px
}
.swatch_block_header{
    color: #000000;
    display: block;
    font-weight: 600;
    margin: 0;
    padding: 0 0 5px;
    font-size: 12px;
}
.swatch_block {
    margin: 0 0 15px 0;
}
.swatch_block div input {
    display: none !important;
}
.swatch_block input:checked + label, .swatch_block label:hover {
    border-color: #000000;
    border-width: 3px;
    line-height: 28px;
}
.swatch_block div {
    display: inline-block;
}
.swatch_block div label {
    border: 1px solid #cbcbcb;
    cursor: pointer;
    font-size: 11px;
    font-weight: 400;
    line-height: 32px;
    margin: 0 15px 0px 0;
    padding: 0 5px;
    text-align: center;
    text-transform: uppercase;
    transform: translateZ(0px);
    white-space: nowrap;
    color: #000;
}
.product-variation-price{
    margin: 0 0 20px 0;
}
.product-variation-price span{
    margin: 0 15px 0 0;
    display: inline-block;
    vertical-align: middle;
}
.discoutn_price{
    color: #ff0000;
}
.price-amount {
    color: #000;
    font-size: 20px;
    font-weight: 600;
}
.variation-availability{
    color: #77a464;
    margin: 0 0 15px 0;
    font-size: 11px;
}

.quantity_block_wrap{
    width: 120px;
    margin: 0 0 20px 0;
}
.quantity_block_wrap span{
    color: #000000;
    font-size: 12px;
    font-weight: 600;
}
.product_quantity_content{
    font-size: 15px;
}

.refer-total-price{
    color: #000000;
    font-size: 12px;
    font-weight: 600;
    margin:0 0 30px 0;
}
.refer-total-price span{
    color: #2d2d2d;
    font-size: 16px;
    font-weight: 700;
    display: inline-block;
    margin: 0 0 0 5px;
}
.recurring-title{
    color: #363636;
    font-size: 20px;
    font-weight: bold;
    margin: 0 0 10px 0;
}
.bold-ro__bold-active {
    background-color: #f5f5f5;
    border-color: #dbdbdb;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
}

.subscribe_save_set{
    background-color: transparent;
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    padding: 10px;
}
.subscribe_save_set.active{
    background-color: #f5f5f5;
    border-color: #dbdbdb;
    border-style: solid;
    border-width: 1px;
}
.subscribe_save_set label{
    margin: 0px;
}
.subscribe_save_set label span{
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 0 8px;
    display: inline-block;
    vertical-align: middle;
}
.subscribe_save_note{
    display: block;
    font-weight: normal;
    font-size: 14px;
}
.subscribe_container{
    margin: 0 0 15px 0;
}
.subscribe_container button{
    width: 40%;
    margin: 20px 0 0 0;
}

.product_quick_view_modal{
    width: 800px;
    max-width: 800px;
}
.product_quick_view_modal .modal-content{
    box-shadow: inset 0 0 0 2px #fff, inset 0 0 0 3px #e1e1e1, 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 0px;
}
.product_quick_view_details .price-amount{
    margin: 0 0 25px 0;
    display: block;
}
.product_quick_view_details .short-description p {
    font-size: 13px;
    line-height: 24px;
    color: #4e4e4e;
    font-weight: 700;
    margin: 0 0 24px 0;
}
.product_quick_view_details .product_tvc_wrapper {
    width: 100%;
    margin: 0 0 20px 0;
}
.product_quick_view_details .product_tvc {
    height: 192px;
    width: 100%;
}
.quick_view_product_option{
    background: #f1f1f1;
    width: 100%;
    border: none;
    padding: 10px;
}
.quick_view_product_option label,
.quick_view_product_option select{
    display: block;
    width: 100%;
}
.quick_view_product_option select{
    border: 1px solid #bfb8b8;
    background-color: #FFF;
}
.cus-modal-header {
    padding: 5px 0;
    border: 0px;
}
.cus-modal-header button {
    font-size: 31px;
    position: absolute;
    right: 10px;
    top: 5px;
    z-index: 9;
}
.flexslider .flex-control-nav.flex-control-thumbs{
    margin: 20px 0 0 0;
}
.flexslider .flex-control-nav.flex-control-thumbs li{
    margin: 0 10px 0 0;
}
.flexslider .flex-control-nav.flex-control-thumbs li img,
.flex-viewport .slides li img{
    border: 1px solid #e1e1e1;
}
.subscription_end_date_edit{
    float: right;
}
.edit_toggle.edit_toggle_hide,
.subscription_end_date_edit_calender{
    display: none;
}
.subscription_end_date_edit_calender.highlight{
    display: block;
}
.subscription_end_date_edit_calender a {
    display: inline-block;
}
.subscription_end_date_edit_calender input{
    display: inline-block;
    width: 50%;
}

.drp-calendar .off.available {
    color: #999;
    cursor: not-allowed !important;
    text-decoration: line-through;
    width: 32px;
    height: 32px;
}
.date_by_day {
    padding: 5px 10px;
    width: 32%;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    background: #f6f6f6;
    font-size: 14px;
    height: 40px;
    color: #767676;
}
.team_member_action_wrapper,
.team_member_action.team_member_action_hide{
    display: none;   
}
.team_member_action,
.team_member_action_wrapper.show{
    display: block;
}
.team_member_action_wrapper.show a{
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0 0 0;
}
.team_member_action_wrapper.show a.wc-forward {
    margin: 0;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 15px;
    height: auto;
}

.pl_error_checkbox{
    color:red !important;
}
.pl_error_input{
    border:1px solid red !important;
}
.pl_error_label{

}

/** leaderboard **/
.vertical_cus_tab_wrapper {
    width: 190px;
    padding: 10px 0px;
    min-height: 300px;
    /* float: left; */
    border-width: 1px 1px 0px 1px;
    border-right-style: solid;
    border-right-color: rgb(221, 221, 221);
    display: inline-block;
    vertical-align: top;
}
.vertical_cus_tab{
    border-bottom: 0px;
}
.vertical_cus_tab_wrapper #custom_date_range{
    width: 178px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #DDDDDD;
    color: #000000
}
.custom_date_range{
    width: 178px;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #DDDDDD;
    color: #000000
}
.vertical_cus_tab li a {
    display: block;
    float: left;
    width: 177px;
    margin-bottom: 5px;
    clear: both;
    border-width: 1px 0px 1px 1px !important;
    border-style: solid !important;
    border-color: #DDDDDD !important;
    background: #F7F7F7;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-decoration: none;
    color: #000000 !important;
    border-radius: 0px !important;
}
.vertical_cus_tab_content {
    margin: 0 0 0 12px;
    display: inline-block;
    vertical-align: top;
    width: 630px;
}
.dataTables_wrapper,
.data_table_style{
    width: 100%;
}
.data_table_style tr th {
    font-size: 15px;
    color: #757171;
    font-weight: 500;
}
.data_table_style tr td{
    font-size: 14px;
}
.dataTables_wrapper .dataTables_filter input{
    height: 30px!important;
    margin-bottom: 5px!important;
    background-color: #fff!important;
    border: 1px solid #e1e1e1!important;
    padding: 5px!important;
}
table.dataTable thead th, table.dataTable thead td {
    padding: 10px 18px;
    border-bottom:0px solid #dcd9d9 !important;
}
.table-striped.data_table_style tbody tr:nth-of-type(odd) {
    background-color: #FFF;
}
.table-bordered.data_table_style td, 
.table-bordered.data_table_style th {
    border-width:0 1px 1px 0 !important;
}
table.dataTable tfoot th, table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 0px solid #dcd9d9 !important;
}
.tab_search {
    height: auto;
    margin-top: 5px;
    background: #f6f6f6;
    border-width: 1px 0px 1px 1px ;
    border-style: solid ;
    border-color: #DDDDDD ;
    padding: 5px;
    width: 178px;
}
.tab_search span,
.tab_search input{
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 600;
}

.tab_search button{
    height: 30px;
    line-height: 25px;
    padding: 0 5px!important;
}
.tab_search input{
    width: 30px;
    text-align: center;
}
.success_alert_wrapper{
    display:none;
}
.success_alert_wrapper_show{
    display:block !important;
}

/** EnD leaderboard **/

/** statistics_report_by_date **/
.sTable3{
    width: 100%;
}
.analytics_list {
    margin-bottom: 25px;
}
.current_tam_member_name{
    display: block;
}
.reassign_container{
    font-size: 13px;
}
.page-content.entry-content p {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #2d2d2d;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px !important;
}
.analytics_list span {
    display: block;
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 8px;
    line-height: 36px;
    color: #767676;
}
.analytics_list p {
    font-weight: 600;
    line-height: 14px;
    margin-top: 0;
}
.statistics_report_by_date .sales_report_date,
.statistics_report_by_date .select,
.statistics_report_by_date button,
.statistics_report_by_date input{
    display: inline-block;
    vertical-align: middle;

}
.statistics_report_by_date input.datepickercustom {
    background-color: #f6f6f6;
    height: 35px;
    text-align: center;
    width: 78px;
    border: 1px solid #aaa !important;
    border-radius: .25rem;
    margin: 0 5px 0 0px;
}
.statistics_report_by_date .sales_report_date {
    background-color: #f6f6f6;
    width: 185px;
    height: 35px;
    padding: 6px 10px 5px 10px;
    border: 1px solid #aaa !important;
    border-radius: .25rem;
    margin: 0 5px 0 0px;
}
.statistics_report_by_date select{
    width: 300px !important;
    height: auto !important;
    height: 43px !important;
}
/** EnD statistics_report_by_date **/
a.link-to-shop{
    color: #FFF !important
}
.receive_order_details thead tr th{
    padding: 0 10px;
}

.checkout-payment-card-opt img {
    margin: 0 0 0 8px;
    display: inline-block;
    vertical-align: middle;
}
.table_search_form_group{
    display: inline-block;
}

.loading {
    background-image: url(../images/icon/dot.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 65px;
    min-height: 450px;
    margin-top: -15px;
}


.home-loading{
    background-image: url(../images/preloader.gif);
    background-repeat: no-repeat;
    background-position: center center;
    min-height:500px;  
    margin: auto;  
}

.cus_pagination .page-item .page-link{
    color: #777777;
}
.cus_pagination .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #afb2b5;
    border-color: #afb2b5;
}

.customer_end_date_edit_calender a,
.customer_end_date_edit_calender input.cus_field.datepicker{
    margin: 0 0 0 10px;
}
.cutomer_address_messages{
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #2d2d2d;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 10px; 
}
.customer_edit_address {
    font-size: 16px;
    display: block;
    font-weight: normal;
    margin: 0 0 20px 0;
    color: #4e4e4e !important;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    text-transform: inherit !important;
}
.customer_address_edit_icon{
    font-size: 16px;
}
.per_month{
    color: #767676 !important;
}
.td_width {
    width: 300px;
}
.subscription_end_date_edit_calender input {
    display: inline-block;
    width: 50%;
    margin: 0 5px;
}
.subscription_end_date_edit_calender a {
    display: inline-block;
    margin: 0 5px;
}
.customer_sub_cancellation a, .customer_sub_cancellation button{
    display: inline-block;
    margin: 0 5px 0 0;
}
.customer_continue_sub_cancel p{
    margin: 0px;
}
.customer_continue_sub_cancel p label input {
    margin: 5px 5px 0 0;
    display: inline-block;
    vertical-align: top;
}
.distributor_cart_product {
    width: 80px;
    display: inline-block;
    vertical-align: middle;
}
.distributor_cart_details {
    width: 240px;
    display: inline-block;
    vertical-align: middle;
}
.subscription-checkout button {
    width: 100%;
}
.subscription-checkout {
    margin: 0px;
}
.distributor_cart_table tr td {
    padding: 15px !important;
}
.Price-currencySymbol {
    font-weight: bold;
    font-size: 18px;
}
.toTitleCase{
    text-transform: capitalize;
}

.payment-method {
    padding: 0 20px 0 0;
}
.textwidget{
    font-weight: 300;
    font-size: 12px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #FFF;
}


.payment-method {
    padding: 0 20px 0 0;
}
.textwidget{
    font-weight: 300;
    font-size: 12px;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    color: #FFF;
}
.sitedown_container{
    text-align: center;
    margin-top: 25%;
}
.sitedown_container img{
    text-align: center;
    margin-bottom: 5%;
}

/** Page not found **/
.error404 .page-title {
    font-size: 72px;
    display: inline-block;
    line-height: 1;
    margin: 100px 0 10px 0;
}
.error404 .subtitle {
    font-size: 24px;
    text-transform: uppercase;
    display: block;
    margin: 0 0 30px 0;
}
.error404 p {
    margin: 0 0 30px 0;
    font-size: 16px !important;
    line-height: 1.5 !important;
}
/** End Page not found **/

/** siteNote **/

.siteNoteWrapper {
    position: fixed;
    left: 0;
    bottom: 0;
}

.siteNote {
    position: fixed;
    bottom: 0;
    width: auto;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: rgb(61, 57, 53, 0.7);
    border-radius: 4px;
    color: #FFF;
    font-size: 15px;
    padding: 10px 15px;
    text-align: center;
    vertical-align: top;
    display: inline-block;
    border: 1px solid rgb(61, 57, 53);
}
.siteNoteClose {
    color: #FFF;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #FFF;
    padding: 2px 20px;
    margin: 0px 25px;
    border-radius: 3px;
}

.siteNoteWrapper.active .siteNote {
    display: none;
}

.siteNoteWrapperShow {
    display: none;
}

.siteNoteWrapper.active .siteNoteWrapperShow {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    color: #FFF;
    border: 1px solid #FFF;
    text-align: center;
    border-radius: 50%;
    line-height: 29px;
    background-color: #000;
    margin: -40px 0 0 15px;
    cursor: pointer;
}

/** End siteNote **/

.captcha_container h2, .captcha_container .cus_field {
    display: inline-block;
    vertical-align: top;
}

.captcha_container .cus_field {
    width: 50% !important;
    margin: 0 10px;
}

.captcha_container h2 {
    border: 1px solid #bdbdbd;
    margin: 0px;
    text-align: center;
    font-size: 20px;
    height: 42px;
    line-height: 42px;
    width: 150px;
}

.captcha_container button {
    font-size: 18px;
}

.topemailphonecolor a{
    color: #212529;
}

.home-banner-img{
    text-align: center;
}

.home-banner-img img{
    margin-bottom: 25px;
    width: 100%;
}

.home-banner-img img[src^="data:image/gif"]{
    width: auto;
    margin-bottom: 25px;
}
.cust_forgot_pass .alert-success{
    color: #155724 !important;
    background-color: #d4edda !important;
    border-color: #c3e6cb !important;
}

.card-dropdown li {
    padding-left: 10px;
}
.card-dropdown {
    min-width: 100px;
}

.disable:hover {
    background: #ccc;
    cursor: not-allowed;
}